<template>
  <!-- Gráfica: superponer dos datos en el mismo año? Por ejemplo que en un mismo año haya hecho dos reconocimientos.
  Máximo 4 reconocimientos en un rango de 3 años. -->
  <div class="my-digital-health">
    <div class="card">
      <h1 class="subsection__title">
        {{ $t('menu.healthMonitoring.digitalHealth') }}
      </h1>
      <p>
        {{ $t('digitalHealth.intro') }}
      </p>
      <div class="tabs-header">
        <ViewTabs
          :steps="kpiSteps"
          :active-step-id="activeStepId"
          :show-number="false"
          text-position="top"
          @go-to-tab-id="emitTabs"
        />
      </div>
      <div>
        <div
          v-if="activeStepId === 0"
          class="my-digital-health__body"
        >
          <ChartBox
            entity="weight"
            :borderColor="['#36C5E4']"
            :lastDateHeader="[lastDateWeight]"
            :outstandingData="outstandingDataWeight"
            :isLoading="this.$store.state.kpisWeightStore.isLoading"
          >
            <WeightChart
              :storeData="filteredDataWeight"
              :xLabels="filteredXlabelsWeight"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
          <ChartBox
            entity="imc"
            :borderColor="['#36C5E4']"
            :lastDateHeader="[lastDateImc]"
            :outstandingData="outstandingDataImc"
            :isLoading="this.$store.state.kpisImcStore.isLoading"
          >
            <ImcChart
              :storeData="filteredDataImc"
              :xLabels="filteredXlabelsImc"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
          <ChartBox
            entity="bloodPress"
            :borderColor="['#36C5E4', '#f1acde']"
            :lastDateHeader="[lastDateBloodPress]"
            :outstandingData="outstandingDataBloodPressSys"
            :outstandingData2="outstandingDataBloodPressDia"
            :isLoading="this.$store.state.kpisBloodPressStore.isLoading"
          >
            <BloodPressChart
              v-if="outstandingDataBloodPressDia || outstandingDataBloodPressSys"
              :storeData="filteredDataDiastolic"
              :storeData2="filteredDataSystolic"
              :xLabels="filteredXlabelsBloodPress"
              :borderColor="['#EF9DD8', '#36C5E4']"
            />
          </ChartBox>
          <ChartBox
            v-if="true"
            entity="vision"
            entity2="vision-left"
            entity3="vision-right"
            :subtitle="[$t('digitalHealth.charts.vision.subtitle'), $t('digitalHealth.charts.vision-left.subtitle'), $t('digitalHealth.charts.vision-right.subtitle')]"
            :borderColor="['#F28C00', '#36C5E4', '#f1acde']"
            :lastDateHeader="[lastDateVision, lastDateVisionLeft, lastDateVisionRight]"
            :outstandingData="outstandingDataVision"
            :outstandingData2="outstandingDataVisionLeft"
            :outstandingData3="outstandingDataVisionRight"
            :isLoading="this.$store.state.kpisVisionStore.isLoading"
            :showTitle="true"
            :showTitle2="false"
            :showTitle3="false"
            :subLabels="datesVision"
            :subLabels2="datesVisionLeft"
            :subLabels3="datesVisionRight"
          >
            <VisionChart
              :storeData="filteredDataVision"
              :xLabels="filteredXlabelsVision"
              :borderColor="'#F28C00'"
            />
            <VisionChart
              :storeData="filteredDataVisionLeft"
              :xLabels="filteredXlabelsVisionLeft"
              :borderColor="'#36C5E4'"
            />
            <VisionChart
              :storeData="filteredDataVisionRight"
              :xLabels="filteredXlabelsVisionRight"
              :borderColor="'#f1acde'"
            />
          </ChartBox>
          <ChartBox
            entity="hearing"
            entity2="hearing-left"
            entity3="hearing-right"
            :subtitle="[$t('digitalHealth.charts.hearing.subtitle'), $t('digitalHealth.charts.hearing-left.subtitle'), $t('digitalHealth.charts.hearing-right.subtitle')]"
            :borderColor="['#F28C00', '#36C5E4', '#f1acde']"
            :borderColorPrimary="['#00BF71', '#679DD0']"
            :lastDateHeader="[lastDateHearing, lastDateHearingLeft, lastDateHearingRight]"
            :outstandingData="outstandingDataHearing"
            :outstandingData2="outstandingDataHearingLeft"
            :outstandingData3="outstandingDataHearingRight"
            :isLoading="this.$store.state.kpisHearingStore.isLoading"
            :showTitle="outstandingDataHearing !== undefined"
            :showTitle2="!(outstandingDataHearing !== undefined) && (outstandingDataHearingLeft !== undefined)"
            :showTitle3="!(outstandingDataHearing !== undefined) && !(outstandingDataHearingLeft !== undefined) && outstandingDataHearingRight !== undefined"
            :subLabels2="datesHearingLeft"
            :subLabels3="datesHearingRight"
          >
            <HearingChart
              v-if="outstandingDataHearing && filteredDataHearing"
              :storeData="filteredDataHearing"
              :xLabels="filteredXlabelsHearing"
              :borderColor="'#F28C00'"
            />
            <HearingLeftChart
              v-if="outstandingDataHearingLeft && filteredDataHearingLeft"
              :storeData="filteredDataHearingLeft"
              :xLabels="filteredXlabelsHearingLeft"
              title="rangos de frecuencias"
              :borderColor="'#36C5E4'"
              :borderColorPrimary="'#00BF71'"
            />
            <HearingRightChart
              v-if="outstandingDataHearingRight && filteredDataHearingRight"
              :storeData="filteredDataHearingRight"
              :xLabels="filteredXlabelsHearingRight"
              title="rangos de frecuencias"
              :borderColor="'#f1acde'"
              :borderColorPrimary="'#679DD0'"
            />
          </ChartBox>
          <ChartBox
            entity="spirometry"
            :lastDateHeader="[lastDateSpirometry]"
            :outstandingData="outstandingDataSpirometry"
            :isLoading="this.$store.state.kpisSpirometryStore.isLoading"
          >
            <SpirometryChart
              v-if="outstandingDataSpirometry && lastDateSpirometry"
              :storeData="filteredDataSpirometry"
              :xLabels="filteredXlabelsSpirometry"
              :test="this.$t('digitalHealth.charts.spirometry.alteredResult')"
            />
          </ChartBox>
        </div>
        <div
          v-if="activeStepId === 1"
          class="my-digital-health__body"
        >
          <h2 v-if="outstandingDataGlucose || outstandingDataUricAcid || outstandingDataTriglycerides ||
                    outstandingDataCholesterolTotal || outstandingDataHdl || outstandingDataLdl ||
                    outstandingDataGgt || outstandingDataGot || outstandingDataGpt">
            {{ $t('digitalHealth.charts.biochemistry.title') }}
          </h2>
          <ChartBox
            entity="glucose"
            entity2="uricAcid"
            entity3="triglycerides"
            :borderColor="['#36C5E4', '#36C5E4', '#36C5E4']"
            :lastDateHeader="[lastDateGlucose, lastDateUricAcid, lastDateTriglycerides]"
            :outstandingData="outstandingDataGlucose ? parseFloat(outstandingDataGlucose) : outstandingDataGlucose"
            :outstandingData2="parseFloat(outstandingDataUricAcid)"
            :outstandingData3="parseFloat(outstandingDataTriglycerides)"
            :isLoading="this.$store.state.kpisUricAcidStore.isLoading || this.$store.state.kpisTriglyceridesStore.isLoading || this.$store.state.kpisGlucoseStore.isLoading"
            :showTitle="true"
            :showTitle2="true"
            :showTitle3="true"
          >
            <GlucoseChart
              v-if="outstandingDataGlucose && lastDateGlucose"
              :storeData="filteredDataGlucose"
              :xLabels="filteredXlabelsGlucose"
              :borderColor="'#36C5E4'"
            />
            <UricAcidChart
              v-if="outstandingDataUricAcid && lastDateUricAcid"
              :storeData="filteredDataUricAcid"
              :xLabels="filteredXlabelsUricAcid"
              :borderColor="'#36C5E4'"
            />
            <TriglyceridesChart
              v-if="outstandingDataTriglycerides && lastDateTriglycerides"
              :storeData="filteredDataTriglycerides"
              :xLabels="filteredXlabelsTriglycerides"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
          <ChartBox
            entity="cholesterolTotal"
            entity2="hdl"
            entity3="ldl"
            :borderColor="['#36C5E4', '#36C5E4', '#36C5E4']"
            :lastDateHeader="[lastDateCholesterolTotal, lastDateHdl, lastDateLdl]"
            :outstandingData="outstandingDataCholesterolTotal ? parseFloat(outstandingDataCholesterolTotal) : outstandingDataCholesterolTotal"
            :outstandingData2="outstandingDataHdl ? parseFloat(outstandingDataHdl) : outstandingDataHdl"
            :outstandingData3="outstandingDataLdl ? parseFloat(outstandingDataLdl) : outstandingDataLdl"
            :isLoading="this.$store.state.kpisCholesterolTotalStore.isLoading || this.$store.state.kpisHdlStore.isLoading || this.$store.state.kpisHdlStore.isLoading"
            :showTitle="true"
            :showTitle2="true"
            :showTitle3="true"
          >
            <CholesterolTotalChart
              v-if="outstandingDataCholesterolTotal && lastDateCholesterolTotal"
              :storeData="filteredDataCholesterolTotal"
              :xLabels="filteredXlabelsCholesterolTotal"
              :borderColor="'#36C5E4'"
            />
            <HdlChart
              v-if="outstandingDataHdl && lastDateHdl"
              :storeData="filteredDataHdl"
              :xLabels="filteredXlabelsHdl"
              :borderColor="'#36C5E4'"
            />
            <LdlChart
              v-if="outstandingDataLdl && lastDateLdl"
              :storeData="filteredDataLdl"
              :xLabels="filteredXlabelsLdl"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
          <ChartBox
            entity="ggt"
            entity2="got"
            entity3="gpt"
            :borderColor="['#36C5E4', '#36C5E4', '#36C5E4']"
            :lastDateHeader="[lastDateGgt, lastDateGot, lastDateGpt]"
            :outstandingData="outstandingDataGgt ? parseFloat(outstandingDataGgt) : outstandingDataGgt"
            :outstandingData2="outstandingDataGot ? parseFloat(outstandingDataGot) : outstandingDataGot"
            :outstandingData3="outstandingDataGpt ? parseFloat(outstandingDataGpt) : outstandingDataGpt"
            :isLoading="this.$store.state.kpisGgtStore.isLoading || this.$store.state.kpisGotStore.isLoading || this.$store.state.kpisGptStore.isLoading"
            :showTitle="true"
            :showTitle2="true"
            :showTitle3="true"
          >
            <GgtChart
              v-if="outstandingDataGgt && lastDateGgt"
              :storeData="filteredDataGgt"
              :xLabels="filteredXlabelsGgt"
              :borderColor="'#36C5E4'"
            />
            <GotChart
              v-if="outstandingDataGot && lastDateGot"
              :storeData="filteredDataGot"
              :xLabels="filteredXlabelsGot"
              :borderColor="'#36C5E4'"
            />
            <GptChart
              v-if="outstandingDataGpt && lastDateGpt"
              :storeData="filteredDataGpt"
              :xLabels="filteredXlabelsGpt"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
          <h2 v-if="outstandingDataHemoglobin || outstandingDataHematies || outstandingDataHematocrito">
            {{ $t('digitalHealth.charts.hematology.title') }}
          </h2>
          <ChartBox
            entity="hemoglobin"
            entity2="hematies"
            entity3="hematocrito"
            :borderColor="['#36C5E4', '#36C5E4', '#36C5E4']"
            :lastDateHeader="[lastDateHemoglobin, lastDateHematies, lastDateHematocrito]"
            :outstandingData="outstandingDataHemoglobin ? parseFloat(outstandingDataHemoglobin) : outstandingDataHemoglobin"
            :outstandingData2="outstandingDataHematies ? parseFloat(outstandingDataHematies) : outstandingDataHematies"
            :outstandingData3="outstandingDataHematocrito ? parseFloat(outstandingDataHematocrito) : outstandingDataHematocrito"
            :isLoading="this.$store.state.kpisHematocritoStore.isLoading || this.$store.state.kpisHematiesStore.isLoading || this.$store.state.kpisHemoglobinStore.isLoading"
            :showTitle="true"
            :showTitle2="true"
            :showTitle3="true"
          >
            <HemoglobinChart
              v-if="outstandingDataHemoglobin && lastDateHemoglobin"
              :storeData="filteredDataHemoglobin"
              :xLabels="filteredXlabelsHemoglobin"
              :borderColor="'#36C5E4'"
            />
            <HematiesChart
              v-if="outstandingDataHematies && lastDateHematies"
              :storeData="filteredDataHematies"
              :xLabels="filteredXlabelsHematies"
              :borderColor="'#36C5E4'"
            />
            <HematocritoChart
              v-if="outstandingDataHematocrito && lastDateHematocrito"
              :storeData="filteredDataHematocrito"
              :xLabels="filteredXlabelsHematocrito"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
          <h2 v-if="outstandingDataPsa">
            {{ $t('digitalHealth.charts.otherParameters.title') }}
          </h2>
          <ChartBox
            entity="psa"
            :borderColor="['#36C5E4']"
            :lastDateHeader="[lastDatePsa]"
            :outstandingData="outstandingDataPsa ? parseFloat(outstandingDataPsa) : outstandingDataPsa"
            :isLoading="this.$store.state.kpisPsaStore.isLoading"
            >
            <PsaChart
              v-if="outstandingDataPsa && filteredDataPsa"
              :storeData="filteredDataPsa"
              :xLabels="filteredXlabelsPsa"
              :borderColor="'#36C5E4'"
            />
          </ChartBox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import ViewTabs from '@/components/ui/ViewTabs.vue'
import ChartBox from '@/components/digital-health/ChartBox.vue'
// import SingleLineChart from '@/components/digital-health/SingleLineChart.vue'
import WeightChart from '@/components/digital-health/WeightChart.vue'
import ImcChart from '@/components/digital-health/ImcChart.vue'
import SpirometryChart from '@/components/digital-health/SpirometryChart.vue'
import VisionChart from '@/components/digital-health/VisionChart.vue'
import HearingChart from '@/components/digital-health/HearingChart.vue'
import HearingLeftChart from '@/components/digital-health/HearingLeftChart.vue'
import HearingRightChart from '@/components/digital-health/HearingRightChart.vue'
import GlucoseChart from '@/components/digital-health/GlucoseChart.vue'
import HemoglobinChart from '@/components/digital-health/HemoglobinChart.vue'
import HematocritoChart from '@/components/digital-health/HematocritoChart.vue'
import HematiesChart from '@/components/digital-health/HematiesChart.vue'
import BloodPressChart from '@/components/digital-health/BloodPressChart.vue'
import CholesterolTotalChart from '@/components/digital-health/CholesterolTotalChart.vue'
import UricAcidChart from '@/components/digital-health/UricAcidChart.vue'
import GgtChart from '@/components/digital-health/GgtChart.vue'
import GotChart from '@/components/digital-health/GotChart.vue'
import GptChart from '@/components/digital-health/GptChart.vue'
import HdlChart from '@/components/digital-health/HdlChart.vue'
import LdlChart from '@/components/digital-health/LdlChart.vue'
import TriglyceridesChart from '@/components/digital-health/TriglyceridesChart.vue'
import PsaChart from '@/components/digital-health/PsaChart.vue'
// import WeightChartExamples from '@/components/digital-health/WeightChartExamples.vue'

export default {
  name: 'DigitalHealth',
  components: { GlucoseChart, PsaChart, HearingRightChart, HearingLeftChart, HearingChart, TriglyceridesChart, HdlChart, LdlChart, GgtChart, GotChart, GptChart, HemoglobinChart, HematocritoChart, HematiesChart, ViewTabs, ChartBox, WeightChart, ImcChart, SpirometryChart, VisionChart, BloodPressChart, CholesterolTotalChart, UricAcidChart },
  data () {
    return {
      activeStepId: 0
    }
  },
  computed: {
    ...mapGetters(['lastDateWeight', 'outstandingDataWeight', 'filteredDataWeight', 'filteredXlabelsWeight']),
    ...mapGetters(['lastDateImc', 'outstandingDataImc', 'filteredDataImc', 'filteredXlabelsImc']),
    ...mapGetters(['lastDateSpirometry', 'outstandingDataSpirometry', 'filteredDataSpirometry', 'filteredXlabelsSpirometry']),
    ...mapGetters(['lastDateVision', 'outstandingDataVision', 'filteredDataVision', 'filteredXlabelsVision', 'filteredXlabelsVisionLeft', 'filteredXlabelsVisionRight']),
    ...mapGetters(['lastDateVisionLeft', 'outstandingDataVisionLeft', 'filteredDataVisionLeft', 'datesHearingLeft', 'datesVisionLeft', 'datesVision']),
    ...mapGetters(['lastDateVisionRight', 'outstandingDataVisionRight', 'filteredDataVisionRight', 'datesHearingRight', 'datesVisionRight']),
    ...mapGetters(['lastDateHearing', 'outstandingDataHearing', 'filteredDataHearing', 'filteredXlabelsHearing']),
    ...mapGetters(['lastDateHearingLeft', 'outstandingDataHearingLeft', 'filteredDataHearingLeft', 'filteredXlabelsHearingRight']),
    ...mapGetters(['lastDateHearingRight', 'outstandingDataHearingRight', 'filteredDataHearingRight', 'filteredXlabelsHearingLeft']),
    ...mapGetters(['lastDateBloodPress', 'outstandingDataBloodPressDia', 'outstandingDataBloodPressSys', 'filteredDataDiastolic', 'filteredDataSystolic', 'filteredXlabelsBloodPress']),
    ...mapGetters(['lastDateGlucose', 'outstandingDataGlucose', 'filteredDataGlucose', 'filteredXlabelsGlucose']),
    ...mapGetters(['lastDateCholesterolTotal', 'outstandingDataCholesterolTotal', 'filteredDataCholesterolTotal', 'filteredXlabelsCholesterolTotal']),
    ...mapGetters(['lastDateUricAcid', 'outstandingDataUricAcid', 'filteredDataUricAcid', 'filteredXlabelsUricAcid']),
    ...mapGetters(['lastDateHemoglobin', 'outstandingDataHemoglobin', 'filteredDataHemoglobin', 'filteredXlabelsHemoglobin']),
    ...mapGetters(['lastDateHematocrito', 'outstandingDataHematocrito', 'filteredDataHematocrito', 'filteredXlabelsHematocrito']),
    ...mapGetters(['lastDateHematies', 'outstandingDataHematies', 'filteredDataHematies', 'filteredXlabelsHematies']),
    ...mapGetters(['lastDateGgt', 'outstandingDataGgt', 'filteredDataGgt', 'filteredXlabelsGgt']),
    ...mapGetters(['lastDateGot', 'outstandingDataGot', 'filteredDataGot', 'filteredXlabelsGot']),
    ...mapGetters(['lastDateGpt', 'outstandingDataGpt', 'filteredDataGpt', 'filteredXlabelsGpt']),
    ...mapGetters(['lastDateHdl', 'outstandingDataHdl', 'filteredDataHdl', 'filteredXlabelsHdl']),
    ...mapGetters(['lastDateLdl', 'outstandingDataLdl', 'filteredDataLdl', 'filteredXlabelsLdl']),
    ...mapGetters(['lastDatePsa', 'outstandingDataPsa', 'filteredDataPsa', 'filteredXlabelsPsa']),
    ...mapGetters(['lastDateTriglycerides', 'outstandingDataTriglycerides', 'filteredDataTriglycerides', 'filteredXlabelsTriglycerides']),
    kpiSteps () {
      return [
        { id: 0, name: this.$tc('generalInfo.medicalTest', 2), disabledTab: false },
        { id: 1, name: this.$tc('generalInfo.analytics', 2), disabledTab: false }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.fetchAllKpis()
  },
  methods: {
    ...mapActions(['fetchWeight', 'fetchImc', 'fetchBloodPress', 'fetchVision', 'fetchHearing', 'fetchHearingLeft', 'fetchHearingRight', 'fetchSpirometry', 'fetchGlucose', 'fetchCholesterolTotal', 'fetchUricAcid']),
    ...mapActions(['fetchHematocrito', 'fetchHematies', 'fetchHemoglobin']),
    ...mapActions(['fetchGgt', 'fetchGot', 'fetchGpt', 'fetchPsa']),
    ...mapActions(['fetchHdl', 'fetchLdl', 'fetchTriglycerides']),
    emitTabs (event) {
      this.activeStepId = event
      window.scrollTo(0, 0)
    },
    fetchAllKpis () {
      this.fetchWeight()
      this.fetchImc()
      this.fetchBloodPress()
      this.fetchVision()
      this.fetchHearing()
      this.fetchHearingLeft()
      this.fetchHearingRight()
      this.fetchSpirometry()
      this.fetchGlucose()
      this.fetchCholesterolTotal()
      this.fetchUricAcid()
      this.fetchHematocrito()
      this.fetchHematies()
      this.fetchHemoglobin()
      this.fetchGgt()
      this.fetchGot()
      this.fetchGpt()
      this.fetchHdl()
      this.fetchLdl()
      this.fetchPsa()
      this.fetchTriglycerides()
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/settings/variables';

.my-digital-health {
  overflow: hidden;
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 13px;
    padding-left: 1px;
    padding-right: 1px;
    & h2 {
      flex-basis: 100%;
      margin-top: 15px;
      margin-bottom: 0;
      font-size: $font-size-xl;
    }
  }
}

</style>
