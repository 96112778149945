<template>
  <div>
    <LineChart
      :data="chartData"
      :chartData="chartData"
      :options="chartOptions"
      :chartId="'line-chart'"
      :datasetIdKey="'label'"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    /><!-- :plugins="chartOptions.plugins" -->
  </div>
</template>

<script>
import LineChart from '@/components/digital-health/LineChart.vue'
import OrangeHeartImg from '@/assets/images/heart-orange.svg'
import GreenHeartImg from '@/assets/images/heart-green.svg'

/* TODO los segmentos podrían ser un objeto de la data que determinara el estilo para todos en vez
de tener que indicarlo en cada uno */

export default {
  name: 'SpirometryChart',
  components: { LineChart },
  props: {
    entity: {
      type: String,
      require: true,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      // eslint-disable-next-line
      type: String | Number,
      default: ''
    },
    measurement: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      require: true,
      default: '#ff0000'
    },
    xLabels: {
      type: Array,
      default: () => []
    },
    storeData: {
      type: Array,
      // default: () => []
      default: () => []
    }
  },
  data () {
    return {
      isLoading: false,
      imageHearth: 'http://localhost:8080/img/logo-preving-contigo.ed6f3766.svg',
      skipped: (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined,
      down: (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined,
      up: (ctx, value) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined,
      referenceData: [],
      width: 242, // 242 // 400 pruebas
      height: 200, // 274 // 360 pruebas
      chartData: {
        type: 'line',
        labels: this.xLabels, // debe ser prop
        datasets: [
          {
            // label: 'Data nº 1', // leyenda superior
            data: this.storeData,
            resultAltered: this.getResultAltered(),
            resultNormal: this.getResultNormal(),
            altered: this.getAltered(),
            normal: this.getNormal(),
            animation: true,
            // líneas
            borderColor: this.borderColor, // color borde
            borderWidth: 0,
            borderCapStyle: 'round', // líneas con final redondeado
            tension: 0.3, // 0: aristas
            showLine: false,
            fill: false,
            spanGaps: true, // mostrar línea en los huecos null
            segment: {
              borderColor: ctx => this.skipped(ctx, this.borderColor) || this.down(ctx, this.borderColor),
              borderDash: ctx => this.skipped(ctx, [4, 10])
            },
            // puntos
            // pointStyle: 'circle',
            pointStyle: this.customPoint,
            // pointStyle: 'http://localhost:8080/img/logo-preving-contigo.ed6f3766.svg',
            // pointStyle: (ctx) => ctx.drawImage(this.imageHearth, 1, 1),
            // pointStyle: (ctx) => { console.log(ctx) },
            // pointStyle: (ctx) => { console.log(ctx) },
            radius: 20, // tamaño del círculo
            // backgroundColor: 'green', // bg de los puntos
            // pointBackgroundColor: (context) => { console.log(context) },
            pointBackgroundColor: (context) => { return context.raw === 1 ? '#84DA7C' : context.raw === 0 ? '#FAB633' : '' },
            hitRadius: 0,
            hoverRadius: 22,
            hoverBorderWidth: 0, // borde en hover
            hoverOffset: 4 // efecto hover?
            // yAxisID: 'yCustom1' // eje personalizado
            // callback: { // BORRAR
            //   pointStyle: function () {
            //     return 'cross'
            //   }
            // }
          }
        ]
        // hoverOffset: 4
      },
      chartOptions: {
        // todo lo que vaya en options va aquí.
        barThickness: 12, //  grosor de las barras
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: true, // animación toda la gráfica
        layout: { // marco del canvas
          padding: 0
          // padding: {
          //   left: 50,
          //   right: 10,
          // }
        },
        interaction: {
          mode: 'point' // point, nearest, index
          // TOOLTIP MODE: 'index' pinta los datos de todas las gráficas superpuestas TOP
        },
        plugins: {
          title: {
            display: false,
            text: this.title,
            padding: {
              top: 5,
              bottom: 5
            },
            color: 'red',
            font: {
              family: "'Calibri', sans-serif",
              size: 18,
              weight: 'lighter',
              style: 'normal'
            }
          },
          subtitle: {
            display: false,
            text: 'Custom Chart Subtitle',
            color: 'red',
            font: {
              family: "'Calibri', sans-serif",
              size: 14,
              weight: 'lighter',
              style: 'normal'
            }
          },
          legend: {
            display: false,
            labels: {
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 12,
                style: 'normal',
                weight: undefined,
                lineHeight: 1.2
              }
            }
          },
          tooltip: {
            // https://www.chartjs.org/docs/latest/configuration/tooltip.html
            enabled: true,
            position: 'average', // nearest
            // filter: tooltipItem => tooltipItem.datasetIndex === 0, // tooltip solo para la primera gráfica
            // backgroundColor: '#000000b8',
            // backgroundColor: (context) => { console.log(context.tooltip.dataPoints[0].raw) },
            // backgroundColor: (context) => { context.tooltip.dataPoints[0].raw === 1 ? 'green' : 'red' },
            backgroundColor: this.colorTooltip,
            // backgroundColor: (context) => { return context.tooltip.dataPoints[0].raw === 1 ? '#000000b8' : '#FAB633b8' },
            // borderColor: '#00ff00', // borde del tooltip
            // borderWidth: 3, // borde del tooltip
            // xAlign: 'center', // left, center, right
            // yAlign: 'bottom', // top, center, bottom
            titleFont: {
              family: "'Helvetica', 'Arial', sans-serif",
              size: 20,
              style: 'normal', // normal, italic, oblique, initial, inherit
              weight: 600,
              lineHeight: 'normal'
            },
            titleColor: '#fff',
            titleAlign: 'center', // center, left, right
            // titleSpacing: 10,
            titleMarginBottom: 10,
            bodyFont: {
              family: "'Helvetica', 'Arial', sans-serif",
              size: 16,
              style: 'normal', // normal, italic, oblique, initial, inherit
              weight: 400,
              lineHeight: 'normal'
            },
            bodyAlign: 'center', // center, left, right
            bodySpacing: 20,
            bodyColor: '#fff', // color texto cuerpo
            padding: 20,
            caretPadding: 10, // distancia extra entre el punto de aparición y el tooltip
            caretSize: 10, // tamaño de la flechita
            cornerRadius: 5,
            multiKeyBackground: '#ff0000',
            // mostrar o no el color del dato
            displayColors: false,
            boxWidth: 20, // ancho
            boxHeight: 10,
            boxPadding: 10,
            usePointStyle: true, // usar el mismo estilo de punto de la gráfica
            // callbacks: {} // para personalizar elementos
            callbacks: {
              label: function (context) {
                console.log(context.dataset)
                return context.parsed.y === 1 ? context.dataset.resultNormal : context.parsed.y === 0 ? context.dataset.resultAltered : ''
              },
              title: function (context) {
                return null
              }
            }
          }
        },
        scales: {
          // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html
          // type: 'logarithmic', // borrar
          // position: 'right', // borrar
          // stacked: true, // no me funciona
          ticks: {
            display: false, // elimino la escala por defecto de la gráfica
            fontSize: 6,
            // stepSize: 10,
            beginAtZero: false
          },
          // yCustom: { // eje personalizado de una gráfica
          //   color: 'blue'
          // },
          x: {
            display: true,
            // type: 'linear',
            // backgroundColor: '#ff0000',
            stacked: true,
            // min: 0,
            // max: 100,
            color: '#fffff',
            grid: {
              display: false, // mostrar parrilla X
              drawBorder: false, // línea X marcada
              lineWidth: 1, // grosor de línea
              color: '#FF0000' // color de línea
            },
            ticks: {
              display: true,
              color: '#B3B3B3',
              font: {
                family: "'Helvetica', 'Arial', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          },
          y: {
            display: true,
            // type: 'linear',
            // backgroundColor: '#ff0000',
            title: {
              display: false, // título del eje Y
              text: 'Eje Y'
            },
            maxTicksLimit: 4,
            stacked: false,
            min: -1.5,
            max: 2.5,
            grid: {
              display: false,
              drawBorder: false,
              lineWidth: 1, // grosor de línea
              color: '#F6F6F6' // color de línea
            },
            ticks: {
              padding: 10,
              stepSize: 0.5,
              display: true, // muestra los labels de Y
              color: '#B3B3B3',
              // showLabelBackdrop: true, // labels en cajas
              // backdropColor: '#ff0000', // labels en cajas
              // textStrokeColor: '#fff000', // labels en cajas
              // textStrokeWidth: 3, // labels en cajas
              font: {
                family: "'Helvetica', 'Arial', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              },
              callback: function (value, index, ticks) {
                /* mensaje en el tooltip en vez de un número */
                console.log(this)
                console.log(ticks)
                return value === 1 ? 'Normal' : value === 0 ? 'Alterada' : ''
              }
            }
          }
        }
      }
    }
  },
  computed: {
    cssClasses () {
      return ''
    },
    styles () {
      return {}
    }
  },
  // mounted () {
  //   console.log('M', this.chartData.datasets[0].data)
  // },
  methods: {
    getLabelForValue (value) {
      console.log('asdfgasdf')
      return 'asdfasdf'
    },
    getResultNormal () {
      console.log('entra')
      return this.$t('digitalHealth.charts.spirometry.normalResult')
    },
    getResultAltered () {
      return this.$t('digitalHealth.charts.spirometry.alteredResult')
    },
    getAltered () {
      return this.$t('digitalHealth.charts.spirometry.altered')
    },
    getNormal () {
      return this.$t('digitalHealth.charts.spirometry.normal')
    },
    colorTooltip (context) {
      // Parece una computada pero si así fuera no funcionaría. De este modo se hace el cálculo
      // en cada vez que se ejecuta el elemento Tooltip, porque hasta entonces no existe.
      // console.log('PRIMER CONTEXT', context.tooltip)
      if (context.tooltip) {
        return context.tooltip.dataPoints[0].raw === 1 ? '#000000b8' : '#FAB633de'
      }
    },
    customPoint (context) {
      // console.log(context.element.options)
      // console.log(context)
      const sun = new Image()
      sun.src = context.raw === 1 ? GreenHeartImg : OrangeHeartImg
      sun.width = 25
      sun.height = 25
      return sun
    }
  }
}
</script>
