<template>
  <div class="welcome-view">
    <div class="welcome-view__container">
      <div class="welcome-view__image-container">
        <WelcomeImage />
      </div>
      <div class="welcome-view__form-container" v-if="isModalVisible">
        <!-- MODAL (After code sended ) -->
            <div class="welcome-view__form__modal" v-if="isModalVisible && !isShowPrevingTermsVisible">
              <span class="welcome-view__form__modal__close icon icon-close" @click="closeModal()"></span>
              <div class="welcome-view__form__modal__content">
                <!-- If user get error not working ERR_REGISTRATION_015 -->
                <div class="welcome-view__form__message" v-if="notEmail && receiveCodeBy.id === RECEIVE_CODE_BY.EMAIL && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
                  <p class="welcome-view__form__subtitle">{{ $t("register.notFoundEmail.title") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-email-not-found"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.notFoundEmail.text')"></p>
                  <div class="login-back-button margin-top-40" @click="closeModal()">
                    <span class="login-back-button__icon icon icon-arrow-left"></span>
                    <span class="login-back-button__label">{{ $t('global.back') }}</span>
                  </div>
                </div>
                <!-- If user get error not working ERR_REGISTRATION_015 -->
                <div class="welcome-view__form__message" v-if="notEmail && receiveCodeBy.id === RECEIVE_CODE_BY.SMS && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
                  <p class="welcome-view__form__subtitle">{{ $t("register.notFoundSms.title") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-email-not-found"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.notFoundSms.text')"></p>
                  <div class="login-back-button margin-top-40" @click="closeModal()">
                    <span class="login-back-button__icon icon icon-arrow-left"></span>
                    <span class="login-back-button__label">{{ $t('global.back') }}</span>
                  </div>
                </div>
                <!-- If user find the code: EMAIL -->
                <div class="welcome-view__form__message" v-if="!notEmail && findedCode && receiveCodeBy.id === RECEIVE_CODE_BY.EMAIL && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
                  <p class="welcome-view__form__subtitle">{{ $t("register.sendedCode") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-email"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.accessInbox')"></p>
                  <Button :label="$t('register.continueRegistration')"
                          :classes="'button--full-width'"
                          :isLoading="isLoading"
                          @click.native="closeModal()" />
                  <a href="javascript:void(0);" class="link margin-vertical-20 margin-auto" @click="setFindedCode()">{{ $t('register.dontFindEmail') }}</a>
                </div>
                <!-- If user find the code: SMS -->
                <div class="welcome-view__form__message" v-if="!notEmail && findedCode && receiveCodeBy.id === RECEIVE_CODE_BY.SMS && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
                  <p class="welcome-view__form__subtitle">{{ $t("register.sendedCode") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-mobile"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.copyCode')"></p>
                  <Button :label="$t('register.continueRegistration')"
                          :classes="'button--full-width'"
                          @click.native="closeModal()" />
                  <a href="javascript:void(0);" class="link margin-vertical-20 margin-auto" @click="setFindedCode()">{{ $t('register.dontFindSMS') }}</a>
                </div>
                <!-- If user DON´T find the code: EMAIL -->
                <div class="welcome-view__form__message" v-if="!notEmail && !findedCode && receiveCodeBy.id === RECEIVE_CODE_BY.EMAIL && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
                  <p class="welcome-view__form__subtitle">{{ $t("register.dontFindEmail") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-email-not-found"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.dontFindEmailText', { email : this.emailAdmin })"></p>
                  <Button :label="$t('register.continueRegistration')"
                          :classes="'button--full-width'"
                          @click.native="closeModal()" />
                  <a class="link margin-vertical-20 margin-auto" @click="goToRequestCodeBySms()">{{ $t('register.requestCodeBySMS') }}</a>
                </div>
                <!-- If user DON´T find the code: SMS -->
                <div class="welcome-view__form__message" v-if="!notEmail && !findedCode && receiveCodeBy.id === RECEIVE_CODE_BY.SMS && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
                  <p class="welcome-view__form__subtitle">{{ $t("register.dontFindSMS") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-mobile-not-found"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.dontFindSMSText', { email : this.emailAdmin })"></p>
                  <Button :label="$t('register.continueRegistration')"
                          :classes="'button--full-width'"
                          @click.native="closeModal()" />
                  <a class="link margin-vertical-20 margin-auto" @click="goToRequestCodeByEmail()">{{ $t('register.requestCodeByEmail') }}</a>
                </div>
              </div>
            </div>
        <!-- MODAL LEGAL TEXTS -->
        <LegalModal :texts="text" :type="legalContent" :mode="false" @onClose="showPrevingTerms(false)" v-if="isModalVisible && isShowPrevingTermsVisible"/>
      </div>
      <div class="welcome-view__form-container" v-if="!isModalVisible">
        <WelcomeLanguageSelector />
        <div class="welcome-view__form-content">
          <img
            :src="getLogo()"
            class="welcome-view__form__logo"
            v-if="registrationProcess === REGISTRATION_PROCESS.FINISHED"
          />
          <!-- STEPS -->
          <div class="register-step__container" v-if="registrationProcess !== REGISTRATION_PROCESS.FINISHED">
            <div class="register-step"
            :class="{'register-step--active' : registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION}">
              <div class="register-step__circle">
                <div class="register-step__circle__number">1</div>
              </div>
              <span class="register-step__label">{{ $t('register.identification') }}</span>
            </div>
            <div class="register-step" :class="{'register-step--active' : registrationProcess === REGISTRATION_PROCESS.CONFIRM}">
              <div class="register-step__circle">
                <div class="register-step__circle__number">2</div>
              </div>
              <span class="register-step__label">{{ $t('register.confirmData') }}</span>
            </div>
          </div>
          <!-- MESSAGE PROCESS FINISHED -->
          <div class="" v-if="registrationProcess === REGISTRATION_PROCESS.FINISHED">
                <div class="welcome-view__form__message" >
                  <p class="welcome-view__form__subtitle">{{ $t("register.goodJob") }}</p>
                  <span class="welcome-view__form__message__icon icon icon-email-arroba"></span>
                  <p class="welcome-view__form__message__text" v-html="$t('register.haveEmail', {email: userAdditionalData.email})"></p>
                </div>
                <div class="login-back-button margin-top-20" @click="navigateTo('login')">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label">{{ $t('resetPassword.startSession') }}</span>
              </div>
          </div>
          <!-- <p class="welcome-image__title welcome-view__form__title mobile-visible" v-html="$t('welcome.title')"></p> -->
            <!-- REGISTER IDENTIFICATION FORM: STEP 1 -->
            <div class="welcome-view__form" v-if="!sendedCode && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
              <p class="welcome-view__form__subtitle">{{ $t('register.userRegistration') }}</p>
              <p class="welcome-view__form__text">{{ $t('register.codeText') }}</p>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("register.nif") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('register.nifPlaceholder')"
                    class="input"
                    v-model="nif"
                  />
                  <div class="form-group__message" v-if="nifErrorMessage">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ $t('errorMessage.typeNIF') }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group margin-bottom-20" v-if="hasCode">
                <div class="form-group__label">{{ $t("register.registrationCode") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('register.registrationCodePlaceholder')"
                    class="input"
                    v-model="registrationCode"
                  />
                </div>
              </div>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("register.chooseTypeSendCode") }}</div>
                <div class="form-group__data">
                  <RadioGroup @onChange="setRadioGroupValues($event)" :options="radioOptions" />
                </div>
              </div>
              <p class="welcome-view__form__small-text" v-if="receiveCodeBy.id === RECEIVE_CODE_BY.EMAIL">{{ $t('register.willSendEmail') }}</p>
              <p class="welcome-view__form__small-text" v-if="receiveCodeBy.id === RECEIVE_CODE_BY.SMS">{{ $t('register.willSendSMS') }}</p>
              <div class="form-message" :class="{'form-message--visible margin-bottom-30' : codeError}">
                <span class="form-message__text">{{ this.defaultError }}</span>
              </div>
              <Button :label="$t('register.requestCode')"
                      :classes="'button--cta button--full-width margin-top-40'"
                      :isLoading="isLoading"
                      @click.native="requestCode()" />
              <div class="login-back-button margin-top-40" @click="navigateTo('login')">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label">{{ $t('resetPassword.startSession') }}</span>
              </div>
            </div>
            <!-- REGISTER IDENTIFICATION FORM: STEP 2 -->
            <div class="welcome-view__form" v-if="sendedCode && registrationProcess === REGISTRATION_PROCESS.IDENTIFICATION">
              <p class="welcome-view__form__subtitle">{{ $t('register.userRegistration') }}</p>
              <p class="welcome-view__form__text">{{ $t('register.codeText') }}</p>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("register.nif") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('register.nifPlaceholder')"
                    class="input"
                    v-model="nif"
                    readonly
                  />
                  <!-- <div class="form-group__message">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ $t('errorMessage.typeNIF') }}</span>
                  </div> -->
                </div>
              </div>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("register.registrationCode") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('register.registrationCodePlaceholder')"
                    class="input"
                    v-model="registrationCode"
                  />
                  <div class="form-group__message" v-if="nifCodeError">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ this.defaultError }}</span>
                  </div>
                </div>
              </div>
              <div class="flex justify-end margin-top-5" @click="register()">
                <a href="javascript:void(0);" class="link">{{ $t("register.requestNewCode") }}</a>
              </div>
              <Button :label="getButtonLabel()"
                      :classes="'button--cta button--full-width margin-top-40'"
                      :isLoading="isLoading"
                      @click.native="sendNifAndCode()" />
              <div class="login-back-button margin-top-40" @click="navigateTo('login')">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label">{{ $t('resetPassword.startSession') }}</span>
              </div>
            </div>
            <!-- REGISTER CONFIRM FORM: STEP 3 -->
            <div class="welcome-view__form" v-if="registrationProcess === REGISTRATION_PROCESS.CONFIRM">
              <p class="welcome-view__form__subtitle">{{ $t('register.userRegistration') }}</p>
              <p class="welcome-view__form__text">{{ $t('register.codeText') }}</p>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("register.nif") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('register.nifPlaceholder')"
                    class="input"
                    v-model="getCurrentUser.dni"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("global.name") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    class="input"
                    v-model="getCurrentUser.name"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("global.surname") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    class="input"
                    v-model="getCurrentUser.lastname"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("global.email") }} (*)</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('global.typeHere')"
                    class="input"
                    v-model="userAdditionalData.email"
                  />
                  <div class="form-group__message" v-if="emailErrorMessage">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ this.emailErrorMessage }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("global.phone") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('global.typeHere')"
                    class="input"
                    v-model="userAdditionalData.phone"
                  />
                  <div class="form-group__message" v-if="phoneErrorMessage">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ this.phoneErrorMessage }}</span>
                  </div>
                </div>
              </div>
              <div class="flex direction-column">
                <div class="checkbox__container" :class="{'checkbox__container--selected' : userAdditionalData.previngTerms.selected}" @click="setPrevingterms()">
                  <span class="checkbox__icon icon" :class="userAdditionalData.previngTerms.selected ? 'icon-checkbox-label-active' : 'icon-checkbox-label'"></span>
                  <span class="checkbox__label">{{ $t('register.acceptTerms01') }} <a href='javascript:void(0)' @click='showPrevingTerms(true, "TERMS", false)' class='link'>{{ $t('register.acceptTerms02') }}</a> {{ $t('register.acceptTerms03') }}</span>
                </div>
                <div class="form-group__message" v-if="previngTermsError">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ $t('errorMessage.terms001') }}</span>
                  </div>
                <div class="checkbox__container margin-top-10" :class="{'checkbox__container--selected' : userAdditionalData.allowUseData.selected}" @click="setAllowUseData()">
                  <span class="checkbox__icon icon" :class="userAdditionalData.allowUseData.selected ? 'icon-checkbox-label-active' : 'icon-checkbox-label'"></span>
                  <span class="checkbox__label">{{ $t('register.allowUseData01') }} <a href='javascript:void(0)' @click='showPrevingTerms(true, "PRIVACY", false)' class='link'>{{ $t('register.allowUseData02') }}</a> </span>
                </div>
                <div class="form-group__message" v-if="allowUseDataError">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ $t('errorMessage.terms002') }}</span>
                  </div>
                <div class="checkbox__container margin-top-10" :class="{'checkbox__container--selected' : userAdditionalData.allowUseCom.selected}" @click="setAllowUseCom()">
                  <span class="checkbox__icon icon" :class="userAdditionalData.allowUseCom.selected ? 'icon-checkbox-label-active' : 'icon-checkbox-label'"></span>
                  <span class="checkbox__label">{{ $t('register.allowUseCom') }} </span>
                </div>
                <div class="form-message" :class="{'form-message--visible margin-bottom-30' : codeError}">
                  <span class="form-message__text">{{ this.defaultError }}</span>
                </div>
              </div>
              <Button :label="getButtonLabel()"
                      :classes="'button--cta button--full-width margin-top-40'"
                      :isLoading="isLoading"
                      @click.native="register()" />
              <div class="login-back-button margin-top-40" @click="navigateTo('login')">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label">{{ $t('resetPassword.startSession') }}</span>
              </div>
            </div>
          <LegalLinks @onClickLink="showPrevingTerms(true, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import logoContigo from '@/assets/images/logo-preving-contigo.svg'
import WelcomeImage from '@/components/welcome/WelcomeImage'
import WelcomeLanguageSelector from '@/components/welcome/WelcomeLanguageSelector'
import userService from '@/services/userService.js'
import Button from '@/components/ui/Button.vue'
import RadioGroup from '@/components/ui/RadioGroup.vue'
import LegalLinks from '@/components/welcome/LegalLinks.vue'
import LegalModal from '@/components/welcome/LegalModal.vue'
export default {
  name: 'Register',
  components: { WelcomeImage, WelcomeLanguageSelector, Button, RadioGroup, LegalLinks, LegalModal },
  data () {
    return {
      emailAdmin: `${window.VUE_APP_IEX_FROM}`,
      text: null,
      notEmail: null,
      legalContent: null,
      mode: null,
      serviceTerms: null,
      serviceTermsi18n: null,
      privacyTerms: null,
      privacyTermsi18n: null,
      cookiesTerms: null,
      cookiesTermsi18n: null,
      myAppointmentsData: null,
      isShowPrevingTermsVisible: false,
      nif: '',
      registrationCode: null,
      passwordVisible: false,
      isLoading: false,
      isModalVisible: false,
      sendedCode: false,
      findedCode: true,
      hasCode: false,
      nifCodeError: false,
      codeError: false,
      defaultError: '',
      previngTermsError: false,
      allowUseDataError: false,
      nifErrorMessage: null,
      emailErrorMessage: null,
      phoneErrorMessage: null,
      RECEIVE_CODE_BY: Vue.CONSTANTS.RECEIVE_CODE_BY,
      REGISTRATION_PROCESS: Vue.CONSTANTS.REGISTRATION_PROCESS,
      userAdditionalData: {
        email: '',
        phone: '',
        previngTerms: {
          selected: false,
          label: this.$t('register.acceptTerms')
        },
        allowUseData: {
          selected: false,
          label: this.$t('register.allowUseData')
        },
        allowUseCom: {
          selected: false,
          label: this.$t('register.allowUseCom')
        }
      },
      receiveCodeBy: {
        id: 1,
        label: 'Por email'
      },
      registrationProcess: null,
      radioOptions: [
        { id: 1, label: 'Por email', selected: true },
        { id: 2, label: 'Por SMS', selected: false }
      ],
      isPrevingTermsVisible: false,
      rules: {
        cifnif: value => {
          return !value || this.validateDocumentation(value)
        },
        required: value => !!value,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value)
        },
        phone: value => {
          const pattern = /^(\+\d{1,2})?[\s.-]?\d{3}[\s.-]?\d{3}[\s.-]?\d{3}$/i
          return !value || pattern.test(value)
        }
      }
    }
  },
  mounted () {
    this.registrationProcess = this.REGISTRATION_PROCESS.IDENTIFICATION
    // console.log()
    if (this.getServiceTerms) {
      this.serviceTermsi18n = this.getServiceTerms
      this.setServiceTerms()
    }

    if (this.getPrivacyTerms) {
      this.privacyTermsi18n = this.getPrivacyTerms
      this.setPrivacyTerms()
    }

    if (this.getCookiesTerms) {
      this.cookiesTermsi18n = this.getCookiesTerms
      this.setCookiesTerms()
    }
  },
  methods: {
    goToRequestCodeByEmail () {
      const radioOptions = [
        { id: 1, label: 'Por email', selected: true },
        { id: 2, label: 'Por SMS', selected: false }
      ]
      this.setRadioGroupValues(radioOptions)
      this.isModalVisible = false
      this.findedCode = !this.findedCode
      this.sendedCode = !this.sendedCode
      this.registrationProcess = this.REGISTRATION_PROCESS.IDENTIFICATION
    },
    goToRequestCodeBySms () {
      const radioOptions = [
        { id: 1, label: 'Por email', selected: false },
        { id: 2, label: 'Por SMS', selected: true }
      ]
      this.setRadioGroupValues(radioOptions)
      this.isModalVisible = false
      this.findedCode = !this.findedCode
      this.sendedCode = !this.sendedCode
      this.registrationProcess = this.REGISTRATION_PROCESS.IDENTIFICATION
    },
    setPrevingterms () {
      this.userAdditionalData.previngTerms.selected = !this.userAdditionalData.previngTerms.selected
    },
    setAllowUseData () {
      this.userAdditionalData.allowUseData.selected = !this.userAdditionalData.allowUseData.selected
    },
    setAllowUseCom () {
      this.userAdditionalData.allowUseCom.selected = !this.userAdditionalData.allowUseCom.selected
    },
    setServiceTerms: function () {
      this.serviceTerms = decodeURIComponent(atob(this.serviceTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setPrivacyTerms: function () {
      this.privacyTerms = decodeURIComponent(atob(this.privacyTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setCookiesTerms: function () {
      this.cookiesTerms = decodeURIComponent(atob(this.cookiesTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    showPrevingTerms (visibility, legalContent, mode) {
      if (legalContent === 'COOKIES') {
        this.$store.dispatch('showCookiesBar')
        return
      }
      this.isModalVisible = visibility
      this.legalContent = legalContent
      this.isShowPrevingTermsVisible = visibility
      if (legalContent === 'TERMS') {
        this.text = this.serviceTerms
      } else if (legalContent === 'PRIVACY') {
        this.text = this.privacyTerms
      }
      this.mode = mode
      window.scrollTo(0, 0)
      // this.$store.commit('setLegalContent', legalContent)
    },
    sendNifAndCode () {
      this.isLoading = true
      setTimeout(() => {
        userService.sendNifAndCode(this.nif, this.registrationCode)
          .then((response) => {
            // console.log()
            this.isLoading = false
            this.registrationProcess = this.REGISTRATION_PROCESS.CONFIRM
            this.$store.commit('setCurrentUser', response.data)
            this.userAdditionalData.email = response.data.email
            this.userAdditionalData.phone = response.data.phone
          })
          .catch((error) => {
            this.isLoading = false
            this.defaultError = this.$t('errorMessage.' + error.response.data.message)
            this.nifCodeError = true
            // console.log()
          })
          .finally(() => {
          })
      }, 1000)
    },
    closeModal () {
      this.isModalVisible = false
    },
    setFindedCode () {
      this.findedCode = !this.findedCode
    },
    requestCodeBySMS () {
      this.findedCode = !this.findedCode
    },
    requestCodeByEmail () {
      this.findedCode = !this.findedCode
    },
    setRadioGroupValues (options) {
      this.radioOptions = options
      this.receiveCodeBy = options.filter(option => option.selected)[0]
      // console.log()
      this.nifErrorMessage = false
    },
    navigateTo (path) {
      this.$router.push({ path: path })
    },
    getLogo: function () {
      return logoContigo
    },
    getButtonLabel () {
      if (this.isLoading) {
        return this.$t('global.sending')
      } else {
        return this.$t('global.continue')
      }
    },
    requestCode () {
      this.hasCode = false
      let hasErrors = false
      this.notEmail = false
      this.nifErrorMessage = null
      if (this.rules.required(this.nif) !== true) {
        this.nifErrorMessage = this.$i18n.t('form.validation.required')
        hasErrors = true
      }
      if (this.rules.cifnif(this.nif) !== true) {
        this.nifErrorMessage = 'El formato del documento no es correcto'
        hasErrors = true
      }
      if (hasErrors === true) {
        return
      }
      this.isLoading = true
      let type = ''
      // console.log()
      if (this.receiveCodeBy.id === 2) {
        type = 'sms'
      } else {
        type = 'email'
      }
      setTimeout(() => {
        userService.requestCodeByEmail(this.nif, type)
          .then((response) => {
            this.isLoading = false
            this.sendedCode = true
            this.isModalVisible = true
            // console.log()
            this.codeError = false
            this.defaultError = ''
          })
          .catch((error) => {
            if (error.response.data.message === 'ERR_REGISTRATION_015') {
              console.log('ERR_REGISTRATION_015')
              this.isLoading = false
              this.notEmail = true
              this.isModalVisible = true
              return
            }
            this.codeError = true
            this.isLoading = false
            if (error.response === undefined) {
              this.defaultError = this.$t('errorMessage.generic')
              return
            }
            this.defaultError = this.$t('errorMessage.' + error.response.data.message)
            // console.log()
          })
          .finally(() => {
          })
      }, 1000)
    },
    register () {
      if (this.formHasErrors() === true) {
        return
      }
      this.isLoading = true
      this.hasCode = false
      this.codeError = false
      setTimeout(() => {
        if (!this.userAdditionalData.allowUseData.selected) {
          this.allowUseDataError = true
        } else {
          this.allowUseDataError = false
        }
        if (!this.userAdditionalData.previngTerms.selected) {
          this.previngTermsError = true
        } else {
          this.previngTermsError = false
        }
        if (!this.previngTermsError && !this.allowUseDataError) {
          userService.verifyRegisterData(this.getCurrentUser.dni, this.registrationCode, this.userAdditionalData.email, this.userAdditionalData.phone, this.userAdditionalData.previngTerms.selected, this.userAdditionalData.allowUseData.selected, this.userAdditionalData.allowUseCom.selected)
            .then((response) => {
              this.isLoading = false
              this.registrationProcess = this.REGISTRATION_PROCESS.FINISHED
              // console.log()
            })
            .catch((error) => {
              this.codeError = true
              this.isLoading = false
              if (error.response === undefined) {
                this.defaultError = this.$t('errorMessage.generic')
                return
              }
              this.defaultError = this.$t('errorMessage.' + error.response.data.message)
            })
            .finally(() => {
            })
        } else {
          this.isLoading = false
        }
      }, 1000)
    },
    validateDocumentation (documentation = this.offer.client.documentation) {
      if (this.isCif) {
        const cifPattern = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/
        return cifPattern.test(documentation)
      } else {
        const nifPattern = /^\d{1,8}[a-zA-Z]{1}$/
        const niePattern = /^[XxTtYyZz]{1}[0-9]{7}[a-zA-Z]{1}$/
        return (nifPattern.test(documentation) || niePattern.test(documentation))
      }
    },
    formHasErrors: function () {
      this.emailErrorMessage = null
      this.phoneErrorMessage = null
      let hasErrors = false

      if (this.rules.required(this.userAdditionalData.email) !== true) {
        hasErrors = true
        this.emailErrorMessage = this.$i18n.t('form.validation.required')
      }

      if (this.rules.email(this.userAdditionalData.email) !== true) {
        hasErrors = true
        this.emailErrorMessage = this.$i18n.t('form.validation.email')
      }

      if (this.userAdditionalData.phone !== '' && this.rules.phone(this.userAdditionalData.phone) !== true) {
        hasErrors = true
        this.phoneErrorMessage = this.$i18n.t('form.validation.phone')
      }

      return hasErrors
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getLanguages', 'getPrivacyTerms', 'getServiceTerms', 'getCookiesTerms', 'getLocale', 'getCookiesPreferencesOpened'])
  },
  watch: {
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        this.setPrivacyTerms()
        this.setServiceTerms()
      },
      deep: true
    },
    getPrivacyTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.privacyTermsi18n = this.getPrivacyTerms
          this.setPrivacyTerms()
        }
      },
      deep: true
    },
    getServiceTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.serviceTermsi18n = this.getServiceTerms
          this.setServiceTerms()
        }
      },
      deep: true
    },
    getCookiesTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.cookiesTermsi18n = this.getCookiesTerms
          this.setCookiesTerms()
        }
      },
      deep: true
    },
    getCookiesPreferencesOpened: {
      handler (newVal, oldVal) {
        if (newVal === true) {
          // this.showPrevingTerms(true, 'COOKIES', false)
          this.isModalVisible = newVal
          this.legalContent = 'COOKIES'
          this.isShowPrevingTermsVisible = newVal
          this.text = this.cookiesTerms
          this.mode = false
          window.scrollTo(0, 0)
        }
      },
      deep: true
    }
  }
}
</script>
