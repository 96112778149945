<template>
  <div class="">
    <VideoModal :data="null" :modalWidth="680" :src="'https://www.youtube.com/embed/me0wLcZ_ntg'" :size="'md'" :isLoading="false" @onClose="closeDocModal()" v-if="isModalVisible" />
    <h1 class="subsection__title">
      {{ $t('home.telemedicina') }}
    </h1>
    <p>
      {{ $t('telemedicina.intro') }}
    </p>
    <div class="simple-advice grey advice-buttons">
      <img src="@/assets/images/telemedicina.png">
      <div class="display-flex direction-column align-items-center" style="gap:10px">
        <Button
          :label="$t('global.downloadDossier')"
          classes="button"
          style="width: 230px;"
          @click.native="downloadDossier"
        />
        <Button
          :label="$t('telemedicina.platformManual')"
          classes="button"
          style="width: 230px;"
          @click.native="downloadManual"
        />
        <Button
          :label="$t('telemedicina.registrationVideo')"
          classes="button"
          style="width: 230px;"
          @click.native="watchVideo($event)"
        />
      </div>
    </div>
    <div class="simple-advice advice-buttons grey" style="margin-top: 35px;">
      <div class="display-flex justify-space-between" style="gap:10px; font-size: 14px;">
        <img src="@/assets/images/activation-code.svg">
        <div>
          <p style="margin-bottom:0;margin-top:6px;"><strong>{{ $t('yourHealthProgram.activationCode') }}</strong></p>
          <p style="margin-bottom:0">{{activationCode}}</p>
        </div>
      </div>
      <div class="display-flex direction-column align-items-center" style="gap:10px;">
        <Button
          :label="$t('telemedicina.platformRegister')"
          classes="button button--cta"
          style="width: 260px;"
          @click.native="openRegisterTlm($event)"
        />
      </div>
    </div>
    <div class="simple-advice advice-buttons grey" style="margin-top: 35px;">
      <div class="display-flex" style="gap:10px;max-width: 200px; font-size: 14px;">
        <p>{{ $t('telemedicina.registerText') }}</p>
      </div>
      <div class="display-flex direction-column align-items-center" style="gap:10px;">
        <Button
          :label="$t('telemedicina.platformAccess')"
          classes="button button--cta"
          style="width: 260px;"
          @click.native="downloadBtn($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>

import Button from '@/components/ui/Button.vue'
import VideoModal from '@/components/modals/VideoModal.vue'
import pdf from '@/assets/files/dossier_tlm.pdf'
import * as _modules from '../../_helpers/modules.helper.js'
import manual from '@/assets/files/platform_manual.pdf'

export default {
  name: 'Telemedicina',
  components: { Button, VideoModal },
  data () {
    return {
      activationCode: null,
      isModalVisible: false
    }
  },
  mounted () {
    this.activationCode = _modules.tlmCode()
  },
  methods: {
    downloadManual () {
      const fileURL = manual
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.target = '_blank'
      fileLink.rel = 'external'
      fileLink.setAttribute('download', 'Manual de la Plataforma')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    downloadDossier () {
      const fileURL = pdf
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.target = '_blank'
      fileLink.rel = 'external'
      fileLink.setAttribute('download', 'Dossier Telemedicina')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    downloadBtn (event) {
      window.open('https://clientes.tuplataformadesalud.com/login', '_blank')
    },
    openRegisterTlm () {
      window.open('https://clientes.tuplataformadesalud.com/preving/registro', '_blank')
    },
    watchVideo () {
      this.isModalVisible = true
    },
    closeDocModal: function () {
      this.isModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/settings/variables';
@import 'src/assets/scss/settings/colors';

.simple-advice {
  display: flex;
  align-items: center;
  padding: 16px 16px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;
  &.advice-buttons {
    justify-content: space-between;
    @media (max-width: 882px) and (orientation: portrait) {
      justify-content: center;
    }
    @media (max-width: 1224px) and (min-width: 1199px) {
      justify-content: center;
    }
  }
  &.grey { background-color: var(--black-5-color) !important; }
  &__icon {
    font-size: 55px;
    margin-right: 10px;
    color: var(--black-60-color);
  }
  & img {
    @media (max-width: 770px) and (min-width: 552px) {
      margin-right: auto;
    }
  }
}
</style>
