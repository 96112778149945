<template>
    <div class="banner__list">
      <!-- TODO: Ocultar banner si hay cita disponible y ya no se pueden crear más -->
          <div class="banner banner__medical-recognition" @click="goToRequestAppoinment()" v-if="isBannerAppoinmentsVisible()">
            <div class="banner__medical-recognition__text-container">
              <span class="banner__medical-recognition__text">{{ $t('banner.medicalExaminationBanner.plan')}}</span>
              <span class="banner__medical-recognition__text banner__medical-recognition__text--bold">{{ $t('banner.medicalExaminationBanner.medicalExamination')}}</span>
            </div>
            <div class="button banner__medical-recognition__button">
              <span class="button__label">{{ $t('banner.medicalExaminationBanner.button') }}</span>
            </div>
          </div>
          <a href="https://www.preving.com/te-llamamos/" target="_blank" class="banner banner__doubts margin-top-20">
            <div class="banner__doubts__text-container">
              <span class="banner__doubts__title">{{ $t('banner.doubtsBanner.someDoubt')}}</span>
              <span class="banner__doubts__title banner__doubts__title--bold">{{ $t('banner.doubtsBanner.questionUs')}}</span>
            </div>
            <div class="banner__doubts__text-container">
              <span class="banner__doubts__text">{{ $t('banner.doubtsBanner.text')}}</span>
            </div>
          </a>
          <a href="https://todoparatuprevencion.com/" target="_blank" class="banner banner__prevention margin-top-20">
            <div class="banner__prevention__text-container">
              <span class="banner__prevention__title" v-html="$t('banner.preventionBanner.title')"></span>
            </div>
            <div class="banner__prevention__text-container">
              <span class="banner__prevention__text">{{ $t('banner.preventionBanner.text') }}</span>
            </div>
          </a>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as _modules from '../../_helpers/modules.helper.js'

export default {
  name: 'Banners',
  data () {
    return {
      menu: []
    }
  },
  mounted () {
    this.menu = [...this.getMenu]
  },
  computed: {
    ...mapGetters(['getMenu', 'getPath', 'getParamSection'])
  },
  methods: {
    isBannerAppoinmentsVisible () {
      const a = _modules.appointmentsDisabled()
      console.log(a)
      if (!a.hasVs) {
        return false
      }
      // has active appointment
      if (a.lastAppointment) {
        this.nextAppoinment = a.lastAppointment
        return false
      }
      if (a.checkExpiredDate) {
        // console.log(a.appointments)
        // console.log(a)
        return false
      }
      return true
    },
    goToRequestAppoinment: function () {
      this.menu.forEach(menu => {
        menu.selected = false
        menu.opened = false
        menu.items.forEach(element => {
          element.selected = false
        })
      })
      this.menu[4].selected = true
      this.menu[4].opened = true
      this.menu[4].items[1].selected = true
      // const menuItem = { label: this.$t('menu.myAppointments.title'), path: '/my-appoinments', icon: 'icon-calendar', selected: true, opened: true }
      // const submenu = { label: this.$t('menu.myAppointments.requestAppointments'), path: '/request-appoinments', component: 'RequestAppoinments', selected: true }
      this.$store.commit('setParamSection', this.menu[4].items[1])
      this.$store.commit('setMenu', this.menu)
      if (this.getPath !== '/my-appoinments') {
        this.$router.push({ path: this.menu[4].path })
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
