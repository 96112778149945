<template>
  <div class="welcome-view">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <div class="welcome-view__container">
      <div class="welcome-view__image-container">
        <WelcomeImage />
      </div>
      <div class="welcome-view__form-container" v-if="isModalVisible">
        <!-- MODAL LEGAL TEXTS -->
        <LegalModal :texts="text" :mode="mode" :type="legalContent" :isLoading="isLoading" @onClose="showPrevingTerms(false)" @onAccept="acceptTerms($event)" v-if="isModalVisible && isShowPrevingTermsVisible"/>
      </div>
      <div class="welcome-view__form-container" v-if="!isModalVisible">
        <WelcomeLanguageSelector v-if="!isModalVisible"/>
        <div class="welcome-view__form-content">
          <img
            :src="getLogo()"
            class="welcome-view__form__logo"
          />
          <div class="width-100-percent">
            <p
              class="
                welcome-image__title
                welcome-view__form__title
                mobile-visible
              "
              v-html="$t('welcome.title')"
            ></p>
            <div v-if="loginError" class="form-message margin-bottom-30">
              <span class="form-message__text">{{ this.defaultError }}</span>
            </div>
            <div class="welcome-view__form">
              <div class="form-group margin-bottom-20">
                <div class="form-group__label">{{ $t("login.username") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('login.usernamePlaceholder')"
                    class="input"
                    v-model="username"
                    v-on:change="usernameErrorMessage = null"
                  />
                </div>
                <div class="form-group__message" v-if="usernameErrorMessage">
                  <span class="form-group__message__icon icon icon-info-fill"></span>
                  <span class="form-group__message__text">{{ usernameErrorMessage }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="form-group__label">{{ $t("login.password") }}</div>
                <div class="form-group__data">
                  <div class="input__container">
                    <span
                      class="input__icon icon"
                      :title="passwordVisible ? $t('login.hidePass') : $t('login.showPass')"
                      @click="setPasswordType()"
                      :class="passwordVisible ? 'icon-hide' : 'icon-show'"
                    ></span>
                    <input
                      :type="passwordVisible ? 'text' : 'password'"
                      :placeholder="$t('login.passwordPlaceholder')"
                      class="input"
                      v-model="password"
                      v-on:change="passwordErrorMessage = null"
                    />
                  </div>
                </div>
                <div class="form-group__message" v-if="passwordErrorMessage">
                  <span class="form-group__message__icon icon icon-info-fill"></span>
                  <span class="form-group__message__text">{{ passwordErrorMessage }}</span>
                </div>
              </div>
              <div class="flex justify-end margin-bottom-20 margin-top-5">
                <a href="javascript:void(0);" class="link" @click="navigateTo('reset-password')">{{ $t("login.forgotPassword") }}</a>
              </div>
              <Button :label="$t('login.enter')"
              :classes="'button--cta button--full-width margin-top-40'"
              :isLoading="isLoading"
              @click.native="login()" />
            </div>
            <div class="margin-top-50">
              <p class="bold margin-0">{{ $t("login.notUserYet") }}</p>
              <p class="margin-bottom-10">{{ $t("login.createAccount") }}</p>
              <Button :label="$t('login.createAccountNow')"
              :classes="'button--cta-secondary button--full-width margin-top-40'"
              @click.native="navigateTo('register')" />
            </div>
          </div>
          <LegalLinks @onClickLink="showPrevingTerms(true, $event, false)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logoContigo from '@/assets/images/logo-preving-contigo.svg'
import WelcomeImage from '@/components/welcome/WelcomeImage'
import WelcomeLanguageSelector from '@/components/welcome/WelcomeLanguageSelector'
import userService from '@/services/userService.js'
import appointmentService from '@/services/appointmentService.js'
import Button from '@/components/ui/Button.vue'
import LegalLinks from '@/components/welcome/LegalLinks.vue'
import LegalModal from '@/components/welcome/LegalModal.vue'
import Toast from '@/components/toasts/Toast.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: { WelcomeImage, WelcomeLanguageSelector, Button, LegalLinks, LegalModal, Toast },
  data () {
    return {
      acceptedTerms: {
        terms: null,
        privacy: null,
        cookies: null
      },
      isToastVisible: false,
      toastParams: null,
      legalContent: null,
      mode: null,
      serviceTerms: null,
      serviceTermsi18n: null,
      privacyTerms: null,
      privacyTermsi18n: null,
      cookiesTerms: null,
      cookiesTermsi18n: null,
      myAppointmentsData: null,
      text: null,
      username: null,
      password: null,
      loginError: false,
      passwordVisible: false,
      isLoading: false,
      showErrors: false,
      defaultError: null,
      usernameErrorMessage: null,
      passwordErrorMessage: null,
      isModalVisible: false,
      legalModal: {},
      isShowPrevingTermsVisible: false,
      languagesInUse: null,
      rules: {
        required: value => !!value,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value)
        }
      },
      languages: [
        {
          id: 1,
          code: 'es'
        },
        {
          id: 2,
          code: 'ca'
        },
        {
          id: 3,
          code: 'eu'
        },
        {
          id: 4,
          code: 'en'
        }
      ]
    }
  },
  created () {
    this.$on('openCookiesLogin')
  },
  beforeDestroy () {
    this.$off('openCookiesLogin')
  },
  mounted () {
    if (this.$route.params.error) {
      const type = 'danger'
      this.loginResult(this.$t('errorMessage.' + this.$route.params.error), type)
    }
    this.languagesInUse = this.getLanguages
    // console.log()
    this.$store.commit('setIsMobileMenuVisible', false)

    if (this.getServiceTerms) {
      this.serviceTermsi18n = this.getServiceTerms
      this.setServiceTerms()
    }

    if (this.getPrivacyTerms) {
      this.privacyTermsi18n = this.getPrivacyTerms
      this.setPrivacyTerms()
    }

    if (this.getCookiesTerms) {
      this.cookiesTermsi18n = this.getCookiesTerms
      this.setCookiesTerms()
    }
  },
  methods: {
    setServiceTerms: function () {
      this.serviceTerms = decodeURIComponent(atob(this.serviceTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setPrivacyTerms: function () {
      this.privacyTerms = decodeURIComponent(atob(this.privacyTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setCookiesTerms: function () {
      this.cookiesTerms = decodeURIComponent(atob(this.cookiesTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    showPrevingTerms (visibility, legalContent, mode) {
      // console.log()
      if (legalContent === 'COOKIES') {
        this.$store.dispatch('showCookiesBar')
        return
      }
      this.isModalVisible = visibility
      this.legalContent = legalContent
      this.isShowPrevingTermsVisible = visibility
      if (legalContent === 'TERMS') {
        this.text = this.serviceTerms
      } else if (legalContent === 'PRIVACY') {
        this.text = this.privacyTerms
      }
      this.mode = mode
      window.scrollTo(0, 0)
      // this.$store.commit('setLegalContent', legalContent)
    },
    navigateTo (path) {
      this.$router.push({ path: path })
    },
    getLogo: function () {
      return logoContigo
    },
    setPasswordType: function () {
      this.passwordVisible = !this.passwordVisible
    },
    formHasErrors: function () {
      this.usernameErrorMessage = null
      this.passwordErrorMessage = null
      let hasErrors = false

      if (this.rules.required(this.username) !== true) {
        hasErrors = true
        this.usernameErrorMessage = this.$i18n.t('form.validation.required')
      }

      if (this.rules.required(this.password) !== true) {
        hasErrors = true
        this.passwordErrorMessage = this.$i18n.t('form.validation.required')
      }

      if (this.username && this.username.includes('@')) {
        if (this.rules.email(this.username) !== true) {
          hasErrors = true
          this.usernameErrorMessage = this.$i18n.t('form.validation.email')
        }
      }

      return hasErrors
    },
    loginResult: function (message, type) {
      this.isToastVisible = true
      this.toastParams = {
        type: type,
        text: `${message}`,
        time: 10000
      }
      setTimeout(() => {
        this.isToastVisible = false
      }, 10000)
    },
    login () {
      if (this.formHasErrors() === true) {
        // console.log()
        return
      }
      this.showErrors = false
      this.isLoading = true
      this.loginError = false
      userService.login(this.username, this.password)
        .then((response) => {
          console.log(response)
          this.$i18n.locale = this.languages.find(x => x.id === response.data.language).code
          sessionStorage.setItem('token', response.data.token)
          sessionStorage.setItem('language', response.data.language)
          const token = 'Bearer ' + response.data.token
          this.$store.commit('setCurrentUser', response.data)
          this.$store.commit('setToken', token)
          this.$store.commit('changeLocale')
          this.initFunctions().finally(() => {
            this.isLoading = false
            this.$router.push({ path: '/' })
          })
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.loginError = true
          // console.log()
          // console.log()
          const termAcceptance = ['ERR_LOGIN_003', 'ERR_LOGIN_004', 'ERR_LOGIN_005']
          if (termAcceptance.indexOf(error.response.data.message) !== -1) {
            this.checkTerms(error.response.data.message)
            return
          }
          if (error.response === undefined) {
            this.defaultError = this.$t('login.errorMessage')
            return
          } else if (error.response.status === 404) {
            this.defaultError = this.$t('login.errorMessage')
            const type = 'danger'
            this.loginResult(this.defaultError, type)
          } else {
            this.passwordErrorMessage = this.$t('errorMessage.' + error.response.data.message)
            // const type = 'danger'
            // this.loginResult(this.defaultError, type)
            this.loginError = true
          }
          // console.log()
          this.showErrors = true
        })
        .finally(() => {
        })
    },
    getMyAppointments () {
      return appointmentService.getMyAppointments()
        .then((response) => {
          // console.log()
          // const a = Crypto.decrypt(response.data)
          // console.log()
          this.$store.commit('setAppointments', response.data)
          sessionStorage.setItem('_appointments', response.data)
        })
        .catch(() => {
          // console.log()
        })
    },
    getCompanies () {
      return userService.getCompanies()
        .then((response) => {
          this.$store.commit('setClientModules', response.data)
          sessionStorage.setItem('_modules', response.data)
        })
        .catch(() => {
          // console.log()
        })
    },
    async initFunctions () {
      await Promise.all([this.getMyAppointments(), this.getCompanies()])
    },
    checkTerms: function (error) {
      if (error === 'ERR_LOGIN_005') {
        // must to accept both
        this.acceptedTerms.privacy = null
        this.acceptedTerms.terms = null
        this.showPrevingTerms(true, 'TERMS', true)
      } else if (error === 'ERR_LOGIN_003') {
        this.acceptedTerms.terms = null
        this.acceptedTerms.privacy = 1
        this.showPrevingTerms(true, 'TERMS', true)
      } else if (error === 'ERR_LOGIN_004') {
        this.acceptedTerms.privacy = null
        this.acceptedTerms.terms = 1
        this.showPrevingTerms(true, 'PRIVACY', true)
      }
    },
    acceptTerms: function (event) {
      // console.log()
      if (this.acceptedTerms.terms === 1 && this.acceptedTerms.privacy === 1) {
        this.isLoading = true
      }

      if (event === 'TERMS') {
        this.acceptedTerms.terms = 1
        this.sendAcceptedTerm(event).finally(() => {
          if (this.acceptedTerms.terms === 1 && this.acceptedTerms.privacy === 1) {
            this.login()
          }
        })
      }
      if (event === 'PRIVACY') {
        this.acceptedTerms.privacy = 1
        this.sendAcceptedTerm(event).finally(() => {
          if (this.acceptedTerms.terms === 1 && this.acceptedTerms.privacy === 1) {
            this.login()
          }
        })
      }

      if (!this.acceptedTerms.terms) this.showPrevingTerms(true, 'TERMS', true)
      if (!this.acceptedTerms.privacy) this.showPrevingTerms(true, 'PRIVACY', true)
    },
    sendAcceptedTerm (type) {
      return userService.loginTerms(this.username, this.password, type)
        .then((response) => {
          // console.log()
        })
        .catch(() => {
          // console.log()
        })
    }
  },
  computed: {
    ...mapGetters(['getCookiesPreferencesOpened', 'getLanguages', 'getPrivacyTerms', 'getServiceTerms', 'getCookiesTerms', 'getLocale']),
    ...mapActions(['showCookiesBar']),
    user () {
      return { username: this.username, password: this.password }
    }
  },
  watch: {
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        this.setPrivacyTerms()
        this.setServiceTerms()
        this.setCookiesTerms()
      },
      deep: true
    },
    getPrivacyTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.privacyTermsi18n = this.getPrivacyTerms
          this.setPrivacyTerms()
        }
      },
      deep: true
    },
    getServiceTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.serviceTermsi18n = this.getServiceTerms
          this.setServiceTerms()
        }
      },
      deep: true
    },
    getCookiesTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.cookiesTermsi18n = this.getCookiesTerms
          this.setCookiesTerms()
        }
      },
      deep: true
    },
    getCookiesPreferencesOpened: {
      handler (newVal, oldVal) {
        if (newVal === true) {
          // this.showPrevingTerms(true, 'COOKIES', false)
          this.isModalVisible = newVal
          this.legalContent = 'COOKIES'
          this.isShowPrevingTermsVisible = newVal
          this.text = this.cookiesTerms
          this.mode = false
          window.scrollTo(0, 0)
        }
      },
      deep: true
    }
  }
}
</script>
