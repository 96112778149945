<template>
  <div class="section__container" v-if="getParamSection" :class="twoColumnsView ? 'section__container--two-columns' : 'section__container--three-columns'">
    <div class="">
      <AsideMenu :data="getMenu[1].items" @onChangeMenu="loadSection($event)" />
      <Banners class="margin-top-20" v-if="getCurrentDevice === DEVICES.TABLET && !twoColumnsView" />
    </div>
    <div class="section__content" v-if="getParamSection">
        <component :is="getParamSection.component" />
    </div>
    <div v-if="!twoColumnsView">
      <Banners v-if="getCurrentDevice !== DEVICES.TABLET" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import userService from '@/services/userService.js'
import AsideMenu from '@/components/commons/AsideMenu.vue'
import DigitalHealth from '@/components/health-monitoring/DigitalHealth.vue'
import YourHealthProgram from '@/components/general-info/YourHealthProgram.vue'
import Telemedicina from '@/components/general-info/Telemedicina.vue'
import Banners from '@/components/commons/Banners.vue'
import * as _modules from '../../_helpers/modules.helper.js'

export default {
  name: 'MyDigitalHealth',
  components: { AsideMenu, Banners, DigitalHealth, YourHealthProgram, Telemedicina },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getCurrentDevice', 'getParamSection', 'getRecognitionData']),
    twoColumnsView () {
      return this.getParamSection.component === 'DigitalHealth' || this.getParamSection.component === 'HealthyGuides'
    }
  },
  created () {
    window.addEventListener('resize', this.onResize)
    // console.log()
  },
  mounted () {
    // console.log()
    // console.log()
    // console.log()
    this.menu = [...this.getMenu]
    if (!this.getParamSection) {
      this.$store.commit('setParamSection', this.getMenu[1].items[0])
      this.menu[1].items[0].selected = true
      this.menu[1].opened = true
      this.menu[1].items[1].disabled = !_modules.hasTps()
      this.menu[1].items[2].disabled = !_modules.hasTelemedicina()
      this.$store.commit('setMenu', this.menu)
    }
    this.loadSection(this.getParamSection)
  },
  methods: {
    loadSection: function (section) {
      this.$store.commit('setParamSection', section)
      this.menu = [...this.getMenu]
      if (!section.selected) {
        this.menu[1].items.forEach(element => {
          element.selected = false
        })
        section.selected = true
        this.menu[1].opened = true
      }
      this.$store.commit('setMenu', this.menu)
    }
  }
}
</script>
