<template>
    <div class="welcome-image">
          <!-- EMPTY SPAN: NO DELETE -->
          <span></span>
          <div class="welcome-image__text-container">
            <p class="welcome-image__title" v-html="$t('welcome.title')"></p>
          <p class="welcome-image__text">{{ $t("welcome.text") }}</p>
          </div>
          <div class="welcome-image__links margin-top-20">
            <span class="welcome-image__link cursor-default">{{ $t('welcome.previngGroup', {year: year} ) }}</span>
            <a href="https://www.preving.com" target="_blank" class="welcome-image__link">{{ $t('welcome.previngURL') }}</a>
            <div class="social-links">
              <span class="social-links__label">{{ $t('global.followUs')}}</span>
              <a href="https://www.facebook.com/preving" target="_blank" class="social-links__icon icon icon-facebook"></a>
              <a href="https://twitter.com/#!/GrupoPreving" target="_blank" class="social-links__icon icon icon-twitter"></a>
              <a href="https://www.linkedin.com/company/581384/" target="_blank" class="social-links__icon icon icon-linkedin"></a>
              <a href="https://www.youtube.com/channel/UCp2f1b_BlCkyRNsbTvx5pdA" target="_blank" class="social-links__icon icon icon-youtube"></a>
              <a href="https://www.instagram.com/grupopreving_/" target="_blank" class="social-links__icon icon icon-instagram"></a>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  name: 'WelcomeImage',
  data () {
    return {
      year: null
    }
  },
  mounted () {
    const date = new Date()
    this.year = date.getFullYear()
  }
}
</script>
