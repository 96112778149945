import axios from 'axios'
import * as store from '../store/index.js'

export function jwtInterceptor () {
  axios.interceptors.request.use(
    request => {
      if (request.url.endsWith('/login') || request.url.endsWith('/register') || request.url.includes('/direct-link-consent')) {
        return request
      }
      // Add the JWT
      const token = store.default.getters.getToken
      if (token) {
        request.headers.Authorization = token
      }
      return request
    },
    err => Promise.reject(err)
  )
  // If a 40x error happens, redirect the user to the login page
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      // console.log()
      if (error.response.status === 401 || error.response.status === 403) {
        // set token null
        window.location.href = 'login'
      } else {
        return Promise.reject(error)
      }
    }
  )
}
