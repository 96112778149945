<template>
    <div class="">
        <Toast :params="toastParams" v-if="isToastVisible"/>
        <h1 class="subsection__title">{{ $t('menu.myProfile.resetPassword') }}</h1>
        <div class="width 100-percent max-width-450 margin-top-20">
        <div class="form-group form-group--my-profile">
            <div class="form-group__label">{{ $t("myProfile.myCurrentPassword") }}</div>
            <div class="form-group__data">
              <div class="input__container">
                <span
                  class="input__icon icon"
                  :title="passwordVisible ? $t('login.hidePass') : $t('login.showPass')"
                  @click="setPasswordType(password)"
                  :class="passwordVisible ? 'icon-hide' : 'icon-show'"
                ></span>
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  :placeholder="$t('login.passwordPlaceholder')"
                  class="input"
                  @keyup="check()"
                  v-model="currentPassword"
                />
                <div class="form-group__message" v-if="requiredPassErrorMessage">
                  <span class="form-group__message__icon icon icon-info-fill"></span>
                  <span class="form-group__message__text">{{ this.requiredPassErrorMessage }}</span>
                </div>
              </div>
            </div>
          </div>
        <div class="form-group form-group--my-profile">
          <div class="form-group__label">{{ $t("myProfile.newPassword") }}</div>
            <div class="form-group__data">
              <div class="input__container">
                <span
                  class="input__icon icon"
                  :title="passwordVisible ? $t('login.hidePass') : $t('login.showPass')"
                  @click="setPasswordType(password)"
                  :class="passwordVisible ? 'icon-hide' : 'icon-show'"
                ></span>
                <input
                  :type="passwordVisible ? 'text' : 'password'"
                  :placeholder="$t('myProfile.newPasswordPlaceholder')"
                  class="input"
                  @keyup="check()"
                  v-model="password"
                />
                <div class="form-group__message" v-if="requiredPassErrorMessage">
                  <span class="form-group__message__icon icon icon-info-fill"></span>
                  <span class="form-group__message__text">{{ this.requiredPassErrorMessage }}</span>
                </div>
              </div>
            </div>
        </div>
        <div class="form-group form-group--my-profile">
          <div class="form-group__label"></div>
          <div class="form-group__data">
            <StrongPassword :password="password" :properties="passwordStrength" @isValidPassword="isValidPassword($event)" />
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline margin-top-20">
          <span class="form-group__label"></span>
          <div class="form-group__data">
            <Button :label="$t('global.save')"
              :classes="'button button--cta min-width-140 mobile-100-percent'"
              :isLoading="isLoading"
              :disabledButton="!passwordValid"
              @click.native="changePassword()" />
          </div>
        </div>
        </div>
    </div>
</template>
<script>
import Toast from '@/components/toasts/Toast.vue'
import Button from '@/components/ui/Button.vue'
import StrongPassword from '@/components/welcome/StrongPassword.vue'
import userService from '@/services/userService.js'
export default {
  name: 'ProfileResetPassword',
  components: { StrongPassword, Button, Toast },
  data () {
    return {
      password: '',
      currentPassword: '',
      requiredPassErrorMessage: null,
      passwordErrorMessage: null,
      passwordValid: false,
      passwordVisible: false,
      isLoading: null,
      buttonDisabled: true,
      isToastVisible: false,
      passwordStrength: {
        uppercase: false,
        lowercase: false,
        numbers: false,
        specialChar: false,
        minLength: false
      },
      passwordStrengthValue: 0,
      rules: {
        required: value => !!value,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value)
        }
      }
    }
  },
  methods: {
    check () {
      if (this.currentPassword !== '' && this.password !== '') {
        this.buttonDisabled = false
      } else {
        this.buttonDisabled = true
      }
    },
    setPasswordType: function (password) {
      this.passwordVisible = !this.passwordVisible
      this.password = password
    },
    passwordResult: function (message, type) {
      // console.log()
      this.isToastVisible = true
      this.toastParams = {
        type: type,
        text: `${message}`,
        time: 5000
      }
      setTimeout(() => {
        this.isToastVisible = false
        this.buttonDisabled = false
      }, 5000)
    },
    isValidPassword (valid) {
      this.passwordValid = valid
      if (this.passwordValid === false) {
        this.passwordErrorMessage = this.$i18n.t('updatePassword.validation.invalid')
      } else {
        this.passwordErrorMessage = null
      }
    },
    changePassword () {
      if (!this.passwordValid) {
        const message = this.$i18n.t('updatePassword.validation.invalid')
        const type = 'danger'
        this.passwordResult(message, type)
        return
      }
      this.buttonDisabled = true
      this.isLoading = true
      // console.log()
      const data = {
        oldPassword: this.currentPassword,
        newPassword: this.password
      }
      userService.changePassword(data)
        .then((response) => {
          // console.log()
          const message = 'Contraseña cambiada correctamente'
          const type = 'success'
          this.passwordResult(message, type)
        })
        .catch((error) => {
          // console.log()
          const type = 'danger'
          this.passwordResult(error.response.data.message, type)
        })
        .finally(() => {
          // console.log()
          this.isLoading = false
          this.password = ''
          this.currentPassword = ''
        })
    }
  }
}
</script>
