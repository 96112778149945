import axios from 'axios'
export default {
  getConsentForm () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/consent-form`
    )
  },
  sendAcceptedConsent (data) {
    return axios.put(
      `${window.VUE_APP_BACKEND_HOST}/informed-consent`,
      { answers: data.answers, consentFormVersionId: data.cfVersion, appointmentId: data.appointmentId, acceptedConsent: data.acceptedConsent, offWorkStatus: data.offWorkStatus, sensibility: data.sensibility, sensibilityTrue: data.sensibilityTrue, locale: data.locale, prolongedLeave: data.prolongedLeave }
    )
  },
  sendAcceptedConsentUri (data, uri) {
    return axios.put(
      `${window.VUE_APP_BACKEND_HOST}/informed-consent/uri?uri=${uri}`,
      { answers: data.answers, consentFormVersionId: data.cfVersion, appointmentId: data.appointmentId, acceptedConsent: data.acceptedConsent, offWorkStatus: data.offWorkStatus, sensibility: data.sensibility, sensibilityTrue: data.sensibilityTrue, locale: data.locale, prolongedLeave: data.prolongedLeave }
    )
  },
  downloadAcceptedConsent (appointmentId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/informed-consent?appointmentId=${appointmentId}`
    )
  },
  directLink (uri) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/direct-link-consent?uri=${uri}`
    )
  },
  loginTerms (uri, type) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/direct-link-consent/terms?type=${type}&uri=${uri}`
    )
  }
}
