<template>
    <div class="radio-group">
        <div class="radio" v-for="(option, index) in options" :key="index" @click="selectOption(option)">
            <span class="radio__circle" :class="{'radio__circle--selected' : option.selected}">
                <span class="radio__circle-selection"></span>
            </span>
            <span class="radio__label" :class="{'radio__label--selected' : option.selected}"> {{option.label}}</span>
        </div>
    </div>
</template>
<script>
export default {
  name: 'RadioGroup',
  props: ['options'],
  data () {
    return {}
  },
  methods: {
    selectOption: function (option) {
      if (!option.selected) {
        this.options.forEach(el => {
          el.selected = false
        })
        option.selected = true
      }
      // console.log()
      this.$emit('onChange', this.options)
    }
  }
}
</script>
