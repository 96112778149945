<template>
  <div class="">
    <VideoModal :data="null" :modalWidth="380" :size="'md'" :src="'https://www.youtube.com/embed/qMT8Y86vL50'" :isLoading="false" @onClose="closeDocModal()" v-if="isModalVisible" />
    <h1 class="subsection__title">
      {{ $t('home.yourHealthProgram') }}
    </h1>
    <p>
      {{ $t('yourHealthProgram.intro') }}
    </p>
    <div class="simple-advice advice-buttons grey">
      <img src="@/assets/images/tpsalud.png">
      <div class="display-flex direction-column align-items-center" style="gap:10px">
        <Button
          :label="$t('global.downloadDossier')"
          classes="button"
          style="width: 230px;"
          @click.native="downloadDossier"
        />
        <Button
          :label="$t('telemedicina.registrationVideo')"
          classes="button"
          style="width: 230px;"
          @click.native="watchVideo($event)"
        />
      </div>
    </div>
    <div class="display-flex justify-space-around" style="margin-top: 35px;">
      <div class="display-flex direction-column align-items-center" style="gap:10px; position:relative">
        <img :class="qrZoom1 ? 'zoom-effect__active' : ''" class="zoom-effect" width="145" src="@/assets/images/false_qr.png">
        <span class="icon icon-search" @click="openQr1" />
        <img width="100" src="@/assets/images/appstore.png" @click="goToappStore" style="cursor:pointer;">
      </div>
      <div class="display-flex direction-column align-items-center" style="gap:10px; position:relative">
        <img :class="qrZoom2 ? 'zoom-effect__active' : ''" class="zoom-effect" width="145" src="@/assets/images/qr_tps.png">
        <span class="icon icon-search" @click="openQr2" />
        <img width="100" src="@/assets/images/googleplay.png" @click="goToGooglePlay" style="cursor:pointer;">
      </div>
    </div>
    <div class="simple-advice grey display-flex" style="gap:10px; margin-top: 35px; justify-content:left;">
      <!-- <span :class="['simple-advice__icon', 'icon', iconClass]"/> -->
      <img src="@/assets/images/activation-code.svg">
      <div style="margin-top: 10px;">
        <p style="margin-bottom:0;margin-top:6px;"><strong>{{ $t('yourHealthProgram.activationCode') }}</strong></p>
        <p style="margin-bottom:0">{{activationCode}}</p>
      </div>
    </div>
  </div>
</template>
<script>

import Button from '@/components/ui/Button.vue'
import * as _modules from '@/_helpers/modules.helper.js'
import VideoModal from '@/components/modals/VideoModal.vue'
import pdf from '@/assets/files/dossier_tps.pdf'

export default {
  name: 'YourHealthProgram',
  components: { Button, VideoModal },
  data () {
    return {
      activationCode: null,
      qrZoom1: false,
      qrZoom2: false,
      isModalVisible: false,
      registerPlatformUrl: 'https://clientes.tuplataformadesalud.com/preving/registro'
    }
  },
  mounted () {
    this.activationCode = _modules.tpsCode()
  },
  methods: {
    downloadDossier () {
      const fileURL = pdf
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.target = '_blank'
      fileLink.rel = 'external'
      fileLink.setAttribute('download', 'Dossier Tu Plataforma de Salud')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    goToappStore () {
      window.open('https://apps.apple.com/es/app/tps-salud/id1621307272', '_blank')
    },
    goToGooglePlay () {
      window.open('https://play.google.com/store/apps/details?id=com.preving.tps', '_blank')
    },
    downloadBtn (event) {
      console.log('CLICK', event.target.innerText)
    },
    openQr1 () {
      this.qrZoom1 = !this.qrZoom1
    },
    openQr2 () {
      this.qrZoom2 = !this.qrZoom2
    },
    watchVideo () {
      this.isModalVisible = true
    },
    closeDocModal: function () {
      this.isModalVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/settings/variables';
@import 'src/assets/scss/settings/colors';

.simple-advice {
  display: flex;
  align-items: center;
  padding: 16px 16px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;
  &.advice-buttons {
    justify-content: space-between;
    @media (max-width: 882px) and (orientation: portrait) {
      justify-content: center;
    }
    @media (max-width: 1224px) and (min-width: 1199px) {
      justify-content: center;
    }
  }
  &.grey { background-color: var(--black-5-color) !important; }
  &__icon {
    font-size: 55px;
    margin-right: 10px;
    color: var(--black-60-color);
  }
  & img {
    @media (max-width: 770px) and (min-width: 552px) {
      margin-right: auto;
    }
  }
}

.zoom-effect {
  transform: scale(1);
  transition: all .1s ease-in;
  &:hover {
    & + span.icon {
      opacity: 1;
    }
  }
  & + span.icon {
    cursor: pointer;
    display: block;
    font-size: 1.6em;
    padding: 10px;
    position: absolute;
    top: 29%;
    border-radius: 30px;
    background: var(--black-15-color);
    color: black;
    opacity: 0;
    transition: all .1s ease-in;
    &:hover {
      opacity: 1;
    }
  }
  &__active {
    position: relative;
    transform: scale(1.7);
    box-shadow: 0px 4px 16px rgb(0 0 0 / 25%);
  }
}

</style>
