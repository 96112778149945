<template>
    <div class="doc-modal__backdrop">
      <div class="doc-modal">
        <div class="doc-modal__header">
            <div class="doc-modal__header__title-container">
                <span class="doc-modal__header__icon icon icon-back" @click="closeModal()"></span>
                <span class="doc-modal__header__title" v-if="docTitle">{{docTitle}}</span>
            </div>
            <button class="button button--small button--positive" @click="downloadFile()">
                <span class="button__icon icon icon-download"></span>
                <span class="button__label">{{ $t('global.download') }}</span>
            </button>
        </div>
        <div class="doc-modal__body" :class="isLoading ? 'loader-container' : ''">
          <span v-if="isLoading" class="icon loader-sppinner icon-is-spinning"/>
          <div v-if="finished">
            <pdfvuer
              v-for="i in numPages"
              :key="i"
              :src="documentData"
              :page="i"
            ></pdfvuer>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
// import pdf from 'vue-pdf'
import pdfvuer from 'pdfvuer'
import * as _files from '@/_helpers/files.helper.js'
export default {
  components: {
    pdfvuer
  },
  name: 'UpDocModal',
  props: ['data', 'isLoading', 'title', 'docTitle'],
  data () {
    return {
      numPages: undefined,
      documentData: null,
      finished: null
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('onClose', false)
    },
    initial () {
      console.log('entra en initial')
      const blob = new Blob([this.documentData])
      const objectUrl = URL.createObjectURL(blob)
      this.pdfsrc = objectUrl
      pdfvuer.createLoadingTask(this.documentData).then(p => {
        this.finished = true
        this.numPages = p.numPages
      })
    },
    downloadFile: function () {
      _files.downloadPDF(this.data, this.docTitle)
    }
  },
  mounted () {
    console.log('entra aqui')
    console.log(this.data)
    if (this.data) {
      this.documentData = 'data:application/pdf;base64,' + this.data
      this.initial()
    }
  },
  watch: {
    data: {
      handler (newVal, oldVal) {
        console.log(oldVal, newVal)
        this.isLoading = false
        this.documentData = 'data:application/pdf;base64,' + this.data
        this.initial()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-sppinner {
  border: 2px solid rgb(187 189 188);
  border-left-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

</style>
