<template>
  <div class="modal__container">
    <div class="modal__backdrop">
      <div
        class="modal"
        :class="modalSize"
        :style="styleDef"
      >
        <div class="modal__header">
          <span class="modal__header__title">{{ title }}</span>
          <span class="modal__header__close icon icon-close" @click="closeModal()"></span>
        </div>
        <div class="modal__body" :style="styleBodyDef">
        <iframe width="560" height="315" :src="src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <!-- <video controls>
            <source :src="src" type="video/mp4">
          </video> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {

  },
  name: 'VideoModal',
  props: ['data', 'isLoading', 'title', 'modalWidth', 'size', 'src'],
  data () {
    return {
      test: null,
      // styleDef: 'width:' + this.modalWidth + 'px; height: auto; overflow-y:hidden;',
      styleDef: 'width: auto; height: auto;',
      styleBodyDef: 'margin-bottom: 10px;',
      srcTest: null
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('onClose', false)
    }
  },
  mounted () {
    this.srcTest = this.src
    console.log(this.srcTest)
    console.log('asdf<')
  },
  computed: {
    modalSize: function () {
      // SIZE OPTIONS: sm, md, lg, full. Default 600px
      return 'modal--' + this.size
    }
  }
}
</script>

<style scoped>

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-sppinner {
  border: 2px solid rgb(187 189 188);
  border-left-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.modal__body {
  max-height: none;
}

iframe {
  max-width: 100%;
  max-height: 80vh;
}

</style>
