<template>
  <div>
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <div class="spinner__init">
      <Spinner
        v-if="!finallyLoad"
        :styles="'stroke: #d7d7d7;'"
      />
    </div>
    <div v-if="finallyLoad && !processFinished">
      <div class="section__container">
        <!-- DEUDA ________________________________________________________
              Selector de provincia: multiselect.
              Checkboxes y Radios con dato preestablecido.
              Flechitas de los iconos a la derecha.
        -->
        <!-- TODO _________________________________________________________
              NAVEGACIÓN
                - Scroll en vertical.
                - Revisar maquetación en la versión móvil.
                - se mantiene la cabecera
                - Botón abajo a 'Volver a Preving contigo' a la sección 'Mis citas'
                - y esta cita debería de eliminar el aviso de 'consentimiento pendiente'.
              Validación de campos:
                - Inputs date, email, tlf... y mensajes de error
              Gestionar el disabled de los botones.
              RadioButtons: que puedan recibir datos preestablecidos.
                - Situación laboral remarcado en Activo y No.
              Crear un componente para 'little-advise':
                - Cuando se acepta el check debería quitarse el fondo.
                - v-model que acepte términos y active el botón siguiente.
                - prop que determine si todo el aviso hace check.
              Último button:
                - Que envíe al servidor, pero por ahora que muestre el data.
                - Que active un loader hasta que se reciba el PDF.
                - Mostraremos un visor pdf estándar, que ya han usado.
        -->
        <div class="section__content section__content--m">
          <!-- STEP 0 -->
          <Tabs
            v-if="activeStepId === 1"
          >
            <div v-html="$t('consentForm.intro')" style="margin-top: 20px"/>

            <InformationCard title="consentForm.basicInformation">
              <p>{{ $t('consentForm.responsable') }}</p>
              <p>{{ responsableCompany }}</p>
              <!-- <p>SPA</p> -->
              <p>{{ $t('consentForm.purpose') }}</p>
              <p>{{ $t('consentForm.purposeAdd') }}</p>
              <p>{{ $t('consentForm.legitimation') }}</p>
              <p>{{ $t('consentForm.legitimationAdd') }}</p>
              <p>{{ $t('consentForm.receiver') }}</p>
              <p>{{ $t('consentForm.receiverAdd') }}</p>
              <p>{{ $t('consentForm.rights') }}</p>
              <p>{{ $t('consentForm.rightsAdd') }}</p>
              <p>{{ $t('consentForm.additionalInfo') }}</p>
              <p v-html="$t('consentForm.additionalInfoAdd')" />
            </InformationCard>
            <InformationCardOneParagraph v-if="appointmentInfo.reasonId === 3" title="consentForm.leave.acceptanceMedicalExamination">
              <br/>
              <p>{{ $t('consentForm.leave.acceptanceIntro') }}</p>
              <b><p>{{ $t('consentForm.leave.acceptanceIntro2') }}</p></b>

              <div class="accept-leave">
                <input
                  id="accept"
                  v-model="prolongedLeave"
                  type="checkbox"
                  class="final-checkbox"
                  name="finalDeclaration"
                >
                <p
                  v-html="$t('consentForm.leave.acceptanceCheck1')"
                />
              </div>
              <div class="accept-leave">
                <input
                  id="not-accept"
                  v-model="prolongedLeave"
                  type="checkbox"
                  class="final-checkbox"
                  name="finalDeclaration"
                  :true-value="false"
                  :false-value="true"
                >
                <p
                  v-html="$t('consentForm.leave.acceptanceCheck2')"
                />
              </div>
              <div class="button-content">
                <Button
                    :label="$t('global.acceptContinue')"
                    :icon="'icon-next icon-rotate-90'"
                    classes="button button--cta"
                    @click.native="nextStep(true, false)"
                />
                <Button
                    :label="$t('form.exitWithoutSaving')"
                    classes="button button--link"
                    @click.native="exitForm"
                />
              </div>
            </InformationCardOneParagraph>
            <InformationCardOneParagraph v-else title="consentForm.acceptanceMedicalExamination">
              <br/>
              <p>{{ $t('consentForm.acceptanceIntro') }}</p>
              <p>{{ $t('consentForm.acceptancePoint1') }}</p>
              <p>{{ $t('consentForm.acceptancePoint2') }}</p>
              <p>{{ $t('consentForm.acceptancePoint3') }}</p>
              <div class="button-content">
                <Button
                    :label="$t('global.acceptContinue')"
                    :icon="'icon-next icon-rotate-90'"
                    classes="button button--cta"
                    @click.native="nextStep(true, false)"
                />
                <Button
                    :label="$t('form.exitWithoutSaving')"
                    classes="button button--link"
                    @click.native="exitForm"
                />
              </div>
            </InformationCardOneParagraph>
          </Tabs>
          <!-- STEP 0.5 -->
          <div v-if="activeStepId >= 2" id="content-tabs">
            <ol id="label-tags">
              <li id="figure" :class="[activeStepId === 2 ? 'active-li' : '' ]" @click="!processFinished?nextStep(2, false):null">1. {{ $t('consentForm.label1')}}</li>
              <li id="figure" :class="[activeStepId === 3 ? 'active-li' : '' ]" @click="!processFinished?nextStep(3, stepOneDisabled):null">2. {{ $t('consentForm.label3')}}</li>
            </ol>
          </div>
          <!-- STEP 1 GENERAL INFORMATION -->
          <Tabs
            v-if="activeStepId === 2"
          >
            <div class="button-content">
              <Button
                :label="$t('global.previous')"
                :icon="'icon-next icon-rotate-270'"
                classes="button button--link"
                @click.native="nextStep(false, false)"
              />
            </div>
            <p>{{ $t('consentForm.checkYourData') }}</p>
            <h4>{{ $t('consentForm.healthExamJob') }}</h4>
            <InputLabel
              v-model="healthExamDate"
              formElement="healthExamDate"
              inputType="text"
              disabled
              textLabel="consentForm.healthExamDate"
            />
            <InputLabel
              v-model="company"
              formElement="company"
              inputType="text"
              disabled
              textLabel="consentForm.company"
            />
            <InputLabel
              v-model="job"
              formElement="job"
              inputType="text"
              disabled
              textLabel="consentForm.job"
            />
            <InputLabel
              v-model="workplace"
              formElement="workplace"
              inputType="text"
              disabled
              textLabel="consentForm.workplace"
            />
            <InputLabel
              v-model="location"
              formElement="location"
              inputType="text"
              disabled
              textLabel="consentForm.location"
            />
            <h4>{{ $t('consentForm.personalData') }}</h4>
            <InputLabel
              v-model="nameSurname"
              formElement="nameSurname"
              inputType="text"
              disabled
              textLabel="consentForm.nameSurname"
            />
            <InputLabel
              v-model="personalId"
              formElement="personalId"
              inputType="text"
              disabled
              textLabel="consentForm.personalId"
            />
            <InputLabel
              v-model="dateOfBirth"
              formElement="dateOfBirth"
              inputType="date"
              :requiredInput="true"
              :disabled="disabledDateofBirth"
              textLabel="consentForm.dateOfBirth"
            />
            <InputLabel
              v-model="email"
              formElement="email"
              inputType="email"
              disabled
              textLabel="consentForm.email"
            />
            <InputLabel
              v-model="phoneNumber"
              formElement="phoneNumber"
              inputType="text"
              :requiredInput="true"
              textLabel="consentForm.phoneNumber"
            />
            <!-- TODO campo 'active' checkeado desde inicio -->
            <RadioButtonsLabel
              v-model="offWorkStatus"
              textLabel="consentForm.employmentStatus"
              groupName="employmentStatus"
              :requiredInput="true"
              :radioOptions="[
                { label: 'consentForm.employmentStatus_active', forId: 'active', value: false },
                { label: 'consentForm.employmentStatus_leave', forId: 'leave', value: true }
              ]"
            />
            <h4>{{ $t('consentForm.habitualResidence') }}</h4>
            <InputLabel
              v-model="address"
              formElement="address"
              inputType="text"
              textLabel="consentForm.address"
            />
            <div class="form-group form-group--inline margin-top-20">
              <label class="form-group__label" style="width: 200px;">{{ $t('myProfile.province') }}</label>
              <div class="form-group__data">
                <Select
                  :itemLabel="'label'"
                  :data="provinces"
                  :class="'form-group__data'"
                  :itemSelected="provinceSelected"
                  :placeholder="'Selecciona...'"
                  @onSelect="setProvince($event)"
                />
              </div>
            </div>
            <div class="form-group form-group--inline margin-top-20">
              <label class="form-group__label" style="width: 200px;">{{ $t('myProfile.city') }}</label>
              <div class="form-group__data">
                <Select
                  :data="cities"
                  :itemSelected="citySelected"
                  :placeholder="'Selecciona...'"
                  @onSelect="setCity($event)"
                />
              </div>
            </div>
            <InputLabel
              v-model="postalCode"
              formElement="postalCode"
              inputType="text"
              textLabel="consentForm.postalCode"
            />
            <div class="button-content">
              <Button
                :label="$t('global.next')"
                :icon="'icon-next icon-rotate-90'"
                classes="button button--cta"
                :disabledButton="stepOneDisabled"
                @click.native="nextStep(true, false)"
              />
            </div>
          </Tabs>
          <!-- STEP 2 CONSENT FORM -->
          <Tabs
            v-if="activeStepId === 3"
          >
            <div class="button-content">
              <Button
                :label="$t('global.previous')"
                :icon="'icon-next icon-rotate-270'"
                classes="button button--link"
                @click.native="nextStep(false)"
              />
            </div>
            <h2>{{ $t('consentForm.healthQuestionnaire') }}</h2>
            <div class="little-advise blue" v-if="appointmentInfo.informedConsent && !previousConsentCompleted">
              <p
                v-html="$t('consentForm.autocompleteQuestion', { lastDateQuestionnaire: lastDateQuestionnaire })"
              />
              <Button
                classes="button"
                :label="$t('form.autocomplete')"
                @click.native="autocompleteForm"
              />
            </div>
            <div v-for="(item, index) in data" :key="index">
              <RadioButtonsLabel
                v-if="item.type === QUESTION_TYPE.RADIO_BOOLEAN || item.type === QUESTION_TYPE.RADIO_BOOLEAN_QUESTION"
                v-model="item.result"
                @input="testInput($event)"
                :requiredInput="item.required"
                :numberLabel="index+1"
                :textLabel="item.description"
                :groupName="item.description"
              />
              <InputLabel
                v-if="(item.result === 'true' || item.result === true) && item.type === QUESTION_TYPE.RADIO_BOOLEAN_QUESTION"
                v-model="item.questionDetails"
                :requiredInput="item.required"
                formElement="medication_text"
                inputType="textarea"
                :maxlength="200"
                :placeholder="$t('global.typeHere')"
                textLabel="consentForm.medication-text"
              />
              <CheckboxesLabel
                v-if="item.type === QUESTION_TYPE.CHECKBOX_MULTIPLE"
                v-model="item.results"
                :numberLabel="index+1"
                :requiredInput="item.required"
                :textLabel="item.description"
                :groupName="item.description"
                :labelOnTop="true"
                :checkboxOptions="item.options"
              />
              <div
                class="form-radio-group boxing-style"
                style="padding:0; grid-template-columns: 100%;padding-top: 25px;display: grid;grid-template-columns: 100%;"
                v-if="item.type === QUESTION_TYPE.TEXT"
              >
                <div class="form-radio-group__header">
                  <div class="card__icon-container">
                    <span class="card__icon">{{ index+1 }}</span>
                  </div>
                  <p class="form-radio">
                    {{ item.description }}
                  </p>
                </div>
                <div style="padding: 0 20px 0 45px;">
                  <InputLabel
                    v-model="item.result"
                    formElement="otherData"
                    inputType="textarea"
                    :maxlength="200"
                    :noLabel="true"
                    :placeholder="$t('global.typeHere')"
                  />
                </div>
              </div>
            </div>
            <div class="little-advise yellow">
              <input
                id="finalDeclaration"
                v-model="finalDeclaration"
                type="checkbox"
                class="final-checkbox"
                name="finalDeclaration"
                value="true"
              >
              <p
                v-html="$t('consentForm.declarationData')"
              />
            </div>
            <div class="button-content">
              <Button
                :label="$t('global.end')"
                classes="button button--cta"
                :disabledButton="!getConsentCompleted || processFinished"
                @click.native="sendingForm"
                :isLoading="isLoading"
              />
            </div>
          </Tabs>
        </div>
      </div>
    </div>
    <div v-if="processFinished">

    </div>
    <Modal :params="consentFinishedParams" v-if="isModalVisible && processFinished" @onClose="closeModal()" @onDownload="downloadConsent()"/>
  </div>
</template>
<script>
import moment from 'moment'
import Vue from 'vue'
import userService from '@/services/userService.js'
import InputLabel from '@/components/commons/InputLabel.vue'
import RadioButtonsLabel from '@/components/commons/RadioButtonsLabel.vue'
import CheckboxesLabel from '@/components/commons/CheckboxesLabel.vue'
import InformationCard from '../commons/InformationCard.vue'
import Tabs from '../commons/Tabs.vue'
import Button from '@/components/ui/Button.vue'
import consentService from '@/services/consentService.js'
import Select from '@/components/ui/Select.vue'
import Spinner from '@/components/spinners/Spinner.vue'
import Toast from '@/components/toasts/Toast.vue'
import * as _files from '@/_helpers/files.helper.js'
import Modal from '@/components/modals/Modal.vue'
import InformationCardOneParagraph from '@/components/commons/InformationCardOneParagraph.vue'

export default {
  name: 'DirectConsentForm',
  components: { Spinner, Select, InputLabel, RadioButtonsLabel, CheckboxesLabel, InformationCard, InformationCardOneParagraph, Tabs, Button, Toast, Modal },
  data () {
    return {
      processFinished: false,
      appointmentConsents: [],
      finallyEmploymentHistory: false,
      finallyProvinces: false,
      legalContent: null,
      mode: null,
      text: null,
      isModalVisible: false,
      finallyLoad: false,
      previousConsent: null,
      previousConsentCompleted: false,
      isLoading: false,
      basicInformation: {
        title: null,
        responsable: null,
        responsableCompany: null,
        purpose: null,
        purposeAdd: null,
        legitimation: null,
        legitimationAdd: null,
        receiver: null,
        receiverAdd: null,
        rights: null,
        rightsAdd: null,
        additionalInfo: null,
        additionalInfoAdd: null
      },
      userInfo: null,
      acceptanceText: {
        title: null,
        text: null
      },
      consentCompleted: false,
      employmentHistoryData: null,
      myWorkPlace: null,
      offWorkStatus: null,
      consentForm: null,
      appointmentInfo: null,
      QUESTION_TYPE: Vue.CONSTANTS.CONSENT_FORM.QUESTION_TYPE,
      activeStepId: 1,
      disabledDateofBirth: false,
      consentFormReply: {
        finalDeclaration: null,
        dateOfDeclaration: null
      },
      generalData: {
        healthExamDate: null,
        company: null,
        job: null,
        workplace: null,
        location: null,
        nameSurname: null,
        personalId: null,
        dateOfBirth: null,
        email: null,
        phoneNumber: null,
        finalDeclaration: null,
        dateOfDeclaration: null,
        address: null,
        addressLocationId: null,
        postalCode: null
      },
      data: null,
      provinces: [],
      cities: [],
      citySelected: null,
      provinceSelected: null,
      provinceId: null,
      province: null,
      city: null,
      cityId: null,
      prolongedLeave: null,
      myAppointments: [],
      appointment: null,
      appointmentId: null,
      countCompleted: null,
      toastParams: null,
      isToastVisible: false,
      consentFinishedParams: {
        type: 8,
        appointment: null,
        title: this.$i18n.t('consentForm.result.success'),
        text: this.$i18n.t('consentForm.downloadText'),
        date: null,
        size: 'sm'
      }
    }
  },
  computed: {
    lastDateQuestionnaire () {
      if (this.appointmentInfo.informedConsent) {
        return this.$options.filters.moment2(this.appointmentInfo.informedConsent.createdAt)
      } else {
        return null
      }
    },
    computedText: {
      get () { return this.text },
      set (value) { this.text = value }
    },
    responsableCompany: {
      get () { return this.myWorkPlace.spa },
      set (value) { this.myWorkPlace.spa = value }
    },
    getConsentCompleted () {
      let a = 0
      if (this.data === null) return false
      this.data.filter(x => x.required === true).forEach(element => {
        if (!('result' in element) && !('results' in element)) a += 1
      })
      return a === 0 && this.finalDeclaration
    },
    healthExamDate: {
      get () { return moment(this.generalData.healthExamDate).format('YYYY-MM-DD') },
      set (value) { this.generalData.healthExamDate = value }
    },
    company: {
      get () { return this.generalData.company },
      set (value) { this.generalData.company = value }
    },
    job: {
      get () { return this.generalData.job },
      set (value) { this.generalData.job = value }
    },
    workplace: {
      get () { return this.generalData.workplace },
      set (value) { this.generalData.workplace = value }
    },
    location: {
      get () { return this.generalData.location },
      set (value) { this.generalData.location = value }
    },
    nameSurname: {
      get () { return this.generalData.nameSurname },
      set (value) { this.generalData.nameSurname = value }
    },
    personalId: {
      get () { return this.generalData.personalId },
      set (value) { this.generalData.personalId = value }
    },
    dateOfBirth: {
      get () { return moment(this.generalData.dateOfBirth).format('YYYY-MM-DD') },
      set (value) {
        this.generalData.dateOfBirth = value
      }
    },
    email: {
      get () { return this.generalData.email },
      set (value) { this.generalData.email = value }
    },
    phoneNumber: {
      get () { return this.generalData.phoneNumber },
      set (value) { this.generalData.phoneNumber = value }
    },
    employmentStatus: {
      get () { return this.consentFormReply.employmentStatus },
      set (value) { this.consentFormReply.employmentStatus = value }
    },
    sensitivityWorker: {
      get () { return this.consentFormReply.sensitivityWorker },
      set (value) { this.consentFormReply.sensitivityWorker = value }
    },
    sensitivityWorker_opt: {
      get () { return this.consentFormReply.sensitivityWorker_opt },
      set (value) { this.consentFormReply.sensitivityWorker_opt = value }
    },
    sensitivityWorker_opt_other: {
      get () { return this.consentFormReply.sensitivityWorker_opt_other },
      set (value) { this.consentFormReply.sensitivityWorker_opt_other = value }
    },
    address: {
      get () { return this.generalData.address },
      set (value) { this.generalData.address = value }
    },
    addressLocation: {
      get () { return this.generalData.addressLocation },
      set (value) {
        this.generalData.addressLocation = value
        this.generalData.addressLocationId = value
      }
    },
    addressProvince: {
      get () { return this.generalData.addressProvince },
      set (value) { this.generalData.addressProvince = value }
    },
    postalCode: {
      get () { return this.generalData.postalCode },
      set (value) { this.generalData.postalCode = value }
    },
    otherData: {
      get () { return this.consentFormReply.otherData },
      set (value) { this.consentFormReply.otherData = value }
    },
    finalDeclaration: {
      get () { return this.consentFormReply.finalDeclaration },
      set (value) { this.consentFormReply.finalDeclaration = value }
    },
    stepOneDisabled () {
      return this.offWorkStatus !== false
    }
  },
  mounted () {
    this.getUriData(this.$route.params.uri)
  },
  methods: {
    nextStep (step, disabled) {
      if (disabled) return
      if (step === true || step === false) {
        step ? this.activeStepId++ : this.activeStepId === 0 ? this.activeStepId = 0 : this.activeStepId--
      } else {
        this.activeStepId = step
      }
      window.scrollTo(0, 0)
    },
    exitForm () {
      this.$router.push('/my-appoinments')
    },
    testInput (event) {
      console.log(event)
      console.log('test input')
    },
    autocompleteForm () {
      console.log(this.data)
      console.log(this.appointmentInfo)
      if (this.appointmentInfo.informedConsent) {
        if (this.consentForm.version === this.appointmentInfo.informedConsent.consentFormVersion.version) {
          this.previousConsent = JSON.parse(this.appointmentInfo.informedConsent.answers)
          this.data = this.previousConsent.questionary
        }
      }
      this.previousConsentCompleted = true
    },
    closeModal: function () {
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        type: 'success',
        text: this.$t('consentForm.result.success'),
        time: 5000,
        top: true,
        infinite: true
      }
    },
    downloadConsent: function () {
      _files.downloadPDF(this.pdf, 'Consentimiento informado')
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        type: 'success',
        text: this.$t('consentForm.result.success'),
        time: 5000,
        top: true,
        infinite: true
      }
    },
    sendingForm () {
      this.isLoading = true

      this.consentFormReply.dateOfDeclaration = moment().format('YYYY-MM-DD HH:mm:ss')
      this.acceptanceText.title = this.$t('consentForm.acceptanceMedicalExamination')
      this.acceptanceText.text = this.$t('consentForm.acceptanceText')

      this.basicInformation.title = this.$t('consentForm.basicInformation')
      this.basicInformation.responsable = this.$t('consentForm.responsable')
      this.basicInformation.responsableCompany = this.myWorkPlace.spa
      this.basicInformation.purpose = this.$t('consentForm.purpose')
      this.basicInformation.purposeAdd = this.$t('consentForm.purposeAdd')
      this.basicInformation.legitimation = this.$t('consentForm.legitimation')
      this.basicInformation.legitimationAdd = this.$t('consentForm.legitimationAdd')
      this.basicInformation.receiver = this.$t('consentForm.receiver')
      this.basicInformation.receiverAdd = this.$t('consentForm.receiverAdd')
      this.basicInformation.rights = this.$t('consentForm.rights')
      this.basicInformation.rightsAdd = this.$t('consentForm.rightsAdd')
      this.basicInformation.additionalInfo = this.$t('consentForm.additionalInfo')
      this.basicInformation.additionalInfoAdd = this.$t('consentForm.additionalInfoAdd')

      this.generalData.dateOfBirth = moment(this.generalData.dateOfBirth).format('YYYY-MM-DDTHH:mm')
      this.generalData.addressLocationId = this.citySelected ? this.citySelected.id : this.getCurrentUser?.cityId
      this.generalData.healthExamDate = moment(this.appointmentInfo.appointmentDate).format('YYYY-MM-DDTHH:mm')

      const resultData = {
        basicInformation: this.basicInformation,
        generalData: this.generalData,
        acceptance: this.acceptanceText,
        questionary: this.data
      }
      const dataSend = {
        cfVersion: this.consentForm.id,
        appointmentId: this.appointmentInfo.appointmentId,
        acceptedConsent: true,
        prolongedLeave: this.prolongedLeave,
        answers: JSON.stringify(resultData),
        offWorkStatus: this.offWorkStatus,
        locale: this.$i18n.locale
      }
      consentService.sendAcceptedConsentUri(dataSend, this.$route.params.uri)
        .then((response) => {
          this.isModalVisible = true
          this.pdf = response.data
          this.processFinished = true
        })
        .catch((error) => {
          this.isToastVisible = true
          this.toastParams = {
            type: 'danger',
            text: this.$t('consentForm.result.error'),
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
          }, 5000)
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getDate: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD/MM/YYYY')
    },
    setInitialProvince () {
      if (this.province) {
        this.provinces.forEach(province => {
          if (province.name.toLowerCase() === this.province.toLowerCase()) {
            this.provinceSelected = province
          }
        })
      }
    },
    setProvince: function (province) {
      this.provinceSelected = province
      // console.log()
      this.cities = []
      this.citySelected = null
      this.getCities(province.id)
    },
    setCity: function (city) {
      this.citySelected = city
    },
    getCities (provinceId) {
      userService.getCities(provinceId)
        .then((response) => {
          response.data.forEach(city => {
            this.cities.push({ id: city.id, name: city.name, selected: false })
          })
          this.setInitialCity()
        })
        .catch((e) => {
          console.error('An error occurred while fetching the cities: ' + e)
        })
    },
    setInitialCity () {
      this.cities.forEach(city => {
        if (city.id === this.cityId) {
          this.citySelected = city
        }
      })
    },
    initialize () {
      if (this.appointmentInfo !== null && this.appointmentInfo !== undefined) this.healthExamDate = (this.appointmentInfo.appointmentDate)

      this.prolongedLeave = this.appointmentInfo?.reasonId === 3 ? true : null
      this.generalData.nameSurname = this.userInfo.name + ' ' + this.userInfo.surname
      this.generalData.personalId = this.userInfo.dni
      this.generalData.dateOfBirth = this.userInfo.birthDate
      this.generalData.email = this.userInfo.email
      this.generalData.phoneNumber = this.userInfo.mobilePhone
      this.generalData.address = this.userInfo.address
      this.generalData.addressLocation = this.userInfo.city
      this.generalData.addressLocationId = this.userInfo.cityId
      this.generalData.addressProvince = this.userInfo.province
      this.generalData.postalCode = this.userInfo.cp
      this.provinceId = this.userInfo.provinceId
      this.province = this.userInfo.province
      this.city = this.userInfo.city
      this.cityId = this.userInfo.cityId
    },
    getUriData (uri) {
      this.isLoading = true
      consentService.directLink(uri)
        .then((response) => {
          sessionStorage.setItem('language', response.data.workerDetails.language)
          this.userInfo = response.data.workerDetails
          this.appointmentInfo = response.data.appointmentInfo
          this.initialize()
          this.provinces = response.data.provinces
          if (this.provinceId !== null) {
            this.getCities(this.provinceId)
          }
          this.setInitialProvince()
          this.consentForm = response.data.consentForm
          const consentVersion = response.data.consentForm.version
          const find = this.consentForm.consentFormVersionTranslations.find(x => x.languageCode === this.$i18n.locale)
          this.data = JSON.parse(find.contents)
          if (this.appointmentInfo.informedConsents.length > 0) {
            const result = this.appointmentInfo.informedConsents.filter(x => x.consentFormVersion?.version === consentVersion && x.createdAt !== null)
            this.appointmentInfo.informedConsent = result.reduce((a, b) => a.createdAt > b.createdAt ? a : b)
          }
          this.myWorkPlace = response.data.laborRelation
          this.generalData.company = response.data.laborRelation.clientName
          this.generalData.job = response.data.laborRelation.jobName
          this.generalData.workplace = response.data.laborRelation.workplaceName
          this.generalData.location = response.data.laborRelation.clientState
        })
        .catch((error) => {
          this.isToastVisible = true
          this.processFinished = true
          this.toastParams = {
            type: 'danger',
            text: this.$t('consentForm.invalidLink'),
            time: 5000,
            top: true,
            infinite: true
          }
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
          this.finallyLoad = true
        })
    }
  },
  filters: {
    moment: function (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD/MM/YYYY')
    },
    moment2: function (date) {
      console.log(date)
      if (!date) {
        return '-'
      }
      return moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY')
    }
  },
  watch: {
    offWorkStatus: {
      handler (newVal, oldVal) {
        if (newVal === true) {
          this.isToastVisible = true
          this.toastParams = {
            type: 'danger',
            text: this.$t('consentForm.alert.offWorkStatus'),
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
          }, 5000)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .accept-leave {
    padding: 16px;
    border-radius: 6px;
    display: flex;
    justify-content: left;
    align-items: center;
    & p {
      margin-bottom: 0px !important;
    }
    & input {
      margin: 0px 30px 0 0 !important;
    }
  }
  .section__container {
    padding: 0 0 20px 0;
  }

  h4 {
    font-family: Helvetica,Arial,sans-serif !important;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .button-content {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .little-advise {
    padding: 16px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.yellow {
      background-color: #FAECD2 !important;
    }
    &.blue {
      background-color: #ebf2fa !important;
    }
    & p {
      margin-bottom: 0;
    }
  }

  input[type="checkbox"].final-checkbox {
    transform: scale(1.5);
    min-width: 16px;
    min-height: 16px;
    margin: 6px 15px 0 0;
  }

#content-tabs {
      position: sticky;
    z-index: 9;
    top: 82px;
    background-color: var(--white-color);
}

ol#label-tags {
  width: 100%;
  position: absolute;
  top: -82px;
  padding: 0px 0;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  gap: 19px;
  list-style: none;
  background-color: var(--white-color);
  text-align: center;
}

li#figure {
    width: 50%; // 200px
    height: 56px;
    position: relative;
    background: var(--black-10-color);
    padding: 20px 36px;
    color: var(--black-60-color);
    font-weight: 600;
    user-select: none;
    &.active-li {
      color: var(--white-color);
      background: var(--primary-dark-color);
      &:before {
        border-left: 20px solid var(--primary-dark-color);
      }
    }
}
li#figure:first-child:after {
  color: red !important;
  border-left: 0px solid white;
}

li#figure:after {
  content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 18px solid white;
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
}
li#figure:before {
  content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid var(--black-10-color);
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
}

.acceptance-text ol li{
  margin-bottom: 15px;
}

@media (max-width: 800px) {

  .paginator {
    display: none;
  }

  li#figure {
    padding: 20px 0px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
  }
}

@media (max-width: 400px) {

  .button-content {
    flex-direction: column;
  }

  ol#label-tags {
    gap: 12px;
    padding: 0;
    padding-top: 10px;
  }

  li#figure {
    padding: 20px 0px;
    font-size: 10px;
    white-space: nowrap;
    pointer-events: none;
  }
  li#figure.active-li:before {
    border-left: 13px solid var(--primary-dark-color);
  }
  li#figure:before {
    right: -13px;
    border-left: 13px solid var(--black-10-color);
  }
  li#figure:after {
    border-left: 8px solid white;
  }

  .little-advise.blue {
        flex-direction: column;
        gap: 11px;
  }
}

.spinner__init {
    transform: scale(3);
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

</style>
