<template>
    <div class="" v-if="user">
      <Toast :params="toastParams" v-if="isToastVisible"/>
      <div class="profile-box margin-bottom-20" v-if="getCurrentDevice !== DEVICES.MOBILE && dataCompleted < 11">
        <div class="profile-box__group">
          <span class="profile-box__icon icon icon-edit"></span>
          <span class="profile-box__text">{{ $t('myProfile.completeProfileText') }}</span>
        </div>
        <div class="profile-box__group">
          <div class="profile-box__chart__container">
          <span class="profile-box__chart" :style="{width: dataCompleted*10 + '%'}"></span>
        </div>
        <span class="profile-box__result-text">{{dataCompleted}} de 11</span>
      </div>
      </div>
        <h1 class="subsection__title">{{ $t('menu.myProfile.title') }}</h1>
        <div class="profile-userdata-box margin-bottom-20 margin-top-20" v-if="getCurrentDevice === DEVICES.MOBILE">
          <p class="profile-userdata-box__name">{{ user.name }}</p>
          <p class="profile-userdata-box__name">{{ user.surname }}</p>
          <div class="profile-userdata-box__icon-group__container margin-top-20">
            <div class="profile-userdata-box__icon-group">
                <span class="profile-userdata-box__icon-group__icon profile-userdata-box__icon-group__icon icon icon-dni"></span>
                <span class="profile-userdata-box__icon-group__label">{{user.dni}}</span>
            </div>
            <div class="profile-userdata-box__icon-group">
                <span class="profile-userdata-box__icon-group__icon profile-userdata-box__icon-group__icon icon icon-birthday"></span>
                <span class="profile-userdata-box__icon-group__label">{{getBirthDate}}</span>
            </div>
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline margin-top-20" v-if="getCurrentDevice !== DEVICES.MOBILE">
          <span class="form-group__label">{{ $t('myProfile.name') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.name" class="input input--read-only" readonly>
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline"  v-if="getCurrentDevice !== DEVICES.MOBILE">
          <span class="form-group__label">{{ $t('myProfile.surname') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.surname" class="input input--read-only" readonly>
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline" v-if="getCurrentDevice !== DEVICES.MOBILE">
          <span class="form-group__label">{{ $t('myProfile.dni') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.dni" class="input input--read-only" readonly>
          </div>
        </div>
        <!-- <div class="form-group form-group--my-profile form-group--inline" v-if="getCurrentDevice !== DEVICES.MOBILE">
          <span class="form-group__label">{{ $t('myProfile.birthDate') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="getBirthDate" class="input input--read-only" readonly>
          </div>
        </div> -->
        <div class="form-group form-group--my-profile form-group--inline" v-if="getCurrentDevice !== DEVICES.MOBILE">
          <span class="form-group__label">{{ $t('myProfile.birthDate') }}</span>
          <div class="form-group__data">
            <input
              v-model="dateOfBirth"
              name="dateOfBirth"
              type="date"
              class="input form-element--xs"
              @input="inputHandler"
            >
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.language') }}</span>
          <div class="form-group__data">
            <Select
              :data="languages"
              :itemSelected="user.language"
              :placeholder="'Selecciona un idioma'"
              :right = "true"
              class="form-element--xs mobile-100-percent"
              @onSelect="setLanguage($event)" />
          </div>
        </div>
        <h3 class="section__subtitle margin-top-20 margin-bottom-20">{{ $t('myProfile.contact')}}</h3>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.mobilePhone') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.mobilePhone" class="input form-element--xs mobile-100-percent">
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.email') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.email" class="input">
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.province') }}</span>
          <div class="form-group__data">
            <!-- <input type="text" v-model="user.province" class="input"> -->
            <Select
              :itemLabel="'label'"
              :data="provinces"
              :itemSelected="provinceSelected"
              :placeholder="'Selecciona...'"
              class="form-element--sm"
              @onSelect="setProvince($event)" />
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.city') }}</span>
          <div class="form-group__data">
            <!-- <input type="text" v-model="user.city" class="input"> -->
            <Select
              :data="cities"
              :itemSelected="citySelected"
              :placeholder="'Selecciona...'"
              class="form-element--sm"
              @onSelect="setCity($event)" />
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.address') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.address" class="input">
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline">
          <span class="form-group__label">{{ $t('myProfile.postalCode') }}</span>
          <div class="form-group__data">
            <input type="text" v-model="user.cp" class="input form-element--xs mobile-100-percent">
          </div>
        </div>
        <div class="form-group form-group--my-profile form-group--inline margin-top-20">
          <span class="form-group__label" v-if="getCurrentDevice !== DEVICES.MOBILE"></span>
          <div class="form-group__data">
            <Button :label="$t('global.save')"
              :classes="'button--cta button--icon-right min-width-140 margin-top-10 mobile-100-percent'"
              :isLoading="isLoading"
              :disabledButton="isLoading"
              @click.native="updateWorkerData()" />
          </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Select from '@/components/ui/Select.vue'
import userService from '@/services/userService.js'
import Button from '@/components/ui/Button.vue'
import Toast from '@/components/toasts/Toast.vue'
export default {
  name: 'ProfileSection',
  components: { Select, Button, Toast },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      percentage: 60,
      citySelected: null,
      provinceSelected: null,
      isLoading: false,
      isToastVisible: false,
      toastParams: null,
      user: {
        username: '',
        name: '',
        surname: '',
        dni: '',
        birthDate: '',
        language: '',
        mobilePhone: '',
        email: '',
        province: '',
        city: '',
        address: '',
        cp: ''
      },
      languages: [
        {
          id: 1,
          name: this.$i18n.t('global.spanish'),
          code: 'es',
          order: 1,
          active: true,
          activeInExtranet: true,
          activeInIexpedient: true
        },
        {
          id: 2,
          name: this.$i18n.t('global.catalan'),
          code: 'ca',
          order: 2,
          active: false,
          activeInExtranet: true,
          activeInIexpedient: true
        },
        {
          id: 3,
          name: this.$i18n.t('global.basque'),
          code: 'eu',
          order: 3,
          active: false,
          activeInExtranet: false,
          activeInIexpedient: false
        },
        {
          id: 4,
          name: this.$i18n.t('global.english'),
          code: 'en',
          order: 4,
          active: false,
          activeInExtranet: false,
          activeInIexpedient: false
        }
      ],
      provinces: [],
      cities: []
    }
  },
  created () {
    this.initializeUser()
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getCurrentDevice', 'getProvincesStatus', 'getProvinces']),
    getBirthDate: function () {
      return moment(this.user.birthDate).format('DD/MM/YYYY')
    },
    dataCompleted: function () {
      let num = 0
      for (const property in this.user) {
        // console.log()
        // console.log()
        if (this.user[property] !== null && this.user[property] !== '' && property !== 'cityId') {
          num += 1
        }
      }
      if (num > 11) {
        return 11
      }
      return num
    },
    dateOfBirth: {
      get () { return moment(this.user.birthDate).format('YYYY-MM-DD') },
      set (value) {
        this.user.birthDate = value
      }
    }
  },
  mounted () {
    // console.log()
    // this.getProvinces()
    if (this.getProvinces !== null) {
      this.provinces = this.getProvinces
      this.setInitialProvince()
    }
    if (this.getProvincesStatus === 'stopped') this.$store.dispatch('fetchApi')
    // if (this.getProvincesStatus === 'pending') this.skeletonIsVisible = true
    this.getCities(this.getCurrentUser.provinceId)
  },
  methods: {
    setLanguage: function (language) {
      this.user.language = language
    },
    toastResult: function (message, type) {
      this.isToastVisible = true
      this.toastParams = {
        type: type,
        text: `${message}`,
        time: 3000
      }
      setTimeout(() => {
        this.isToastVisible = false
      }, 3000)
    },
    setProvince: function (province) {
      this.provinceSelected = province
      // console.log()
      this.cities = []
      this.citySelected = null
      this.getCities(province.id)
    },
    setCity: function (city) {
      this.citySelected = city
    },
    /*
    setCity: function (city) {
      this.citySelected = city
      // console.log()
    },
    getProvinces () {
      userService.getProvinces()
        .then((response) => {
          response.data.forEach(province => {
            this.provinces.push({ id: province.id, name: province.name, selected: false })
          })
          this.setInitialProvince()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
        })
    },
    */
    getCities (provinceId) {
      userService.getCities(provinceId)
        .then((response) => {
          // console.log()
          response.data.forEach(city => {
            this.cities.push({ id: city.id, name: city.name, selected: false })
          })
          this.setInitialCity()
          // console.log()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
        })
    },
    setInitialProvince () {
      if (this.user.province) {
        this.provinces.forEach(province => {
          if (province.name.toLowerCase() === this.user.province.toLowerCase()) {
            this.provinceSelected = province
          }
        })
      }
    },
    setInitialCity () {
      this.cities.forEach(city => {
        if (city.id === this.user.cityId) {
          this.citySelected = city
        }
      })
    },
    updateWorkerData () {
      this.isLoading = true
      const data = {
        language: this.user.language.id,
        phone: this.user.mobilePhone,
        email: this.user.email,
        cityId: this.citySelected.id || this.user.cityId,
        address: this.user.address,
        zipCode: this.user.cp,
        bornDate: moment(this.dateOfBirth).toISOString()
      }
      console.log(data)
      userService.updateWorkerData(data)
        .then((response) => {
          // console.log()
          this.isLoading = false
          const message = 'Datos actualizados'
          const type = 'success'
          this.toastResult(message, type)
          const l = this.getCurrentUser.language
          this.modifyCurrentUser()
          if (this.user.language.id !== l) {
            // change language
            this.$i18n.locale = this.user.language.code
            sessionStorage.setItem('language', this.user.language.id)
            window.location.reload()
            // this.$i18n.locale = this.user.language.code
          }
        })
        .catch(() => {
          // console.log()
          this.isLoading = false
          const message = 'Ha ocurrido un error'
          const type = 'danger'
          this.toastResult(message, type)
        })
        .finally(() => {
          // console.log()
        })
    },
    modifyCurrentUser () {
      const tempUser = this.getCurrentUser
      tempUser.language = this.user.language.id
      tempUser.mobilePhone = this.user.mobilePhone
      tempUser.email = this.user.email
      tempUser.address = this.user.address
      tempUser.cityId = this.citySelected.id || this.user.cityId
      tempUser.cp = this.user.cp
      tempUser.birthDate = moment(this.user.birthDate).toISOString()
      this.$store.commit('setCurrentUser', tempUser)
    },
    initializeUser () {
      const user = this.getCurrentUser
      // console.log()
      this.user.name = user.name
      this.user.surname = user.surname
      this.user.dni = user.dni
      this.user.birthDate = user.birthDate
      this.user.language = this.languages.find(x => x.id === user.language)
      this.user.mobilePhone = user.mobilePhone
      this.user.email = user.email
      this.user.province = user.province
      this.user.city = user.city
      this.user.address = user.address
      this.user.cp = user.cp
      this.user.cityId = user.cityId
    },
    inputHandler (event) {
      console.log(event.target.value)
      if (event && event.target && event.target.valueAsDate) {
        this.dateOfBirth = event.target.value
      } else {
        this.user.birthDate = null
      }
    }
  },
  watch: {
    getProvincesStatus: {
      handler (newVal) {
        if (newVal === 'success') {
          this.provinces = this.getProvinces
          this.setInitialProvince()
        }
      },
      deep: true
    }
  }
}
</script>
