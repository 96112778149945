<template>
  <div class="toast__container" :class="getContainerClasses">
    <div class="toast" :class="getClasses">
      <span class="toast__icon icon" :class="getIcon"></span>
      <span class="toast__text" v-html="params.text"></span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Toast',
  components: {},
  props: {
    params: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      showToast: true,
      GLOBAL_STATES: Vue.CONSTANTS.GLOBAL_STATES
    }
  },
  computed: {
    ...mapGetters(['getModalComponent']),
    getClasses () {
      const type = this.params.type ? 'toast--' + this.params.type : 'toast--success'
      const visible = this.showToast ? 'toast--visible' : ''
      const size = this.params.size ? 'toast--' + this.params.size : ''
      return type + ' ' + visible + ' ' + size
    },
    getContainerClasses () {
      const position = this.params.top ? 'toast__container_top_center' : ''
      return position
    },
    getIcon () {
      switch (this.params.type) {
        case 'success':
          return 'icon-info-toast'
        case 'warning':
          return 'icon-alert'
        case 'danger':
          return 'icon-error'
        default:
          return 'icon-info-toast'
      }
    }
  },
  mounted () {
    console.log('TOAST MOUNTED')
    // First: animation
    if (!this.params.infinite) {
      setTimeout(() => {
        this.showToast = false
      }, this.params.time || 5000)

      // Second: Toast hidden
      setTimeout(() => {
        this.$store.commit('setToastVisible', false)
      }, this.params.time + 400 || 5000 + 400)
    }
  },
  created () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>
