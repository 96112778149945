import healthService from '@/services/healthService.js'
import preventionService from '@/services/preventionService.js'
import activitiesService from '@/services/activitiesService.js'
import userService from '@/services/userService.js'
import Vue from 'vue'

function initialState () {
  return {
    recognitionData: null,
    recognitionDataStatus: 'stopped',
    proficiencyReports: null,
    proficiencyReportsStatus: 'stopped',
    analytics: null,
    analyticsStatus: 'stopped',
    otherTests: null,
    otherTestsStatus: 'stopped',
    otherDocuments: null,
    otherDocumentsStatus: 'stopped',
    employmentHistory: null,
    employmentHistoryStatus: 'stopped',
    trainingReceived: null,
    trainingReceivedStatus: 'stopped',
    provinces: null,
    provincesStatus: 'stopped'
  }
}

export default {
  state: {
    recognitionData: null,
    recognitionDataStatus: 'stopped',
    proficiencyReports: null,
    proficiencyReportsStatus: 'stopped',
    analytics: null,
    analyticsStatus: 'stopped',

    otherTests: null,
    otherTestsStatus: 'stopped',
    otherDocuments: null,
    otherDocumentsStatus: 'stopped',

    employmentHistory: null,
    employmentHistoryStatus: 'stopped',

    trainingReceived: null,
    trainingReceivedStatus: 'stopped',

    provinces: null,
    provincesStatus: 'stopped'
  },
  mutations: {
    setRecognitionData (state, recognitionData) {
      state.recognitionData = recognitionData
    },
    setRecognitionDataStatus (state, status) {
      state.recognitionDataStatus = status
    },
    setProficiencyReports (state, proficiencyReports) {
      state.proficiencyReports = proficiencyReports
    },
    setProficiencyReportsStatus (state, status) {
      state.proficiencyReportsStatus = status
    },
    setAnalytics (state, analytics) {
      state.analytics = analytics
    },
    setAnalyticsStatus (state, status) {
      state.analyticsStatus = status
    },

    setOtherTests (state, otherTests) {
      state.otherTests = otherTests
    },
    setOtherTestsStatus (state, status) {
      state.otherTestsStatus = status
    },
    setOtherDocuments (state, otherDocuments) {
      state.otherDocuments = otherDocuments
    },
    setOtherDocumentsStatus (state, status) {
      state.otherDocumentsStatus = status
    },

    setEmploymentHistory (state, employmentHistory) {
      state.employmentHistory = employmentHistory
    },
    setEmploymentHistoryStatus (state, status) {
      state.employmentHistoryStatus = status
    },

    setTrainingReceived (state, trainingReceived) {
      state.trainingReceived = trainingReceived
    },
    setTrainingReceivedStatus (state, status) {
      state.trainingReceivedStatus = status
    },
    setProvinces (state, provinces) {
      state.provinces = provinces
    },
    setProvincesStatus (state, status) {
      state.provincesStatus = status
    },
    reset (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  // Must start with 'get'
  getters: {
    getRecognitionData (state) {
      return state.recognitionData
    },
    getRecognitionDataStatus (state) {
      return state.recognitionDataStatus
    },
    getProficiencyReports (state) {
      return state.proficiencyReports
    },
    getProficiencyReportsStatus (state) {
      return state.proficiencyReportsStatus
    },

    getAnalytics (state) {
      return state.analytics
    },
    getAnalyticsStatus (state) {
      return state.analyticsStatus
    },
    getOtherTests (state) {
      return state.otherTests
    },
    getOtherTestsStatus (state) {
      return state.otherTestsStatus
    },
    getOtherDocuments (state) {
      return state.otherDocuments
    },
    getOtherDocumentsStatus (state) {
      return state.otherDocumentsStatus
    },

    getEmploymentHistory (state) {
      return state.employmentHistory
    },
    getEmploymentHistoryStatus (state) {
      return state.employmentHistoryStatus
    },
    getTrainingReceived (state) {
      return state.trainingReceived
    },
    getTrainingReceivedStatus (state) {
      return state.trainingReceivedStatus
    },
    getProvinces (state) {
      return state.provinces
    },
    getProvincesStatus (state) {
      return state.provincesStatus
    }
  },
  actions: {
    fetchProvinces ({ commit }) {
      commit('setProvincesStatus', 'pending')
      userService.getProvinces()
        .then((response) => {
          commit('setProvinces', response.data)
          commit('setProvincesStatus', 'success')
        })
        .catch(() => {
          commit('setProvincesStatus', 'error')
        })
    },
    fetchEmploymentHistory ({ commit }) {
      commit('setEmploymentHistoryStatus', 'pending')
      activitiesService.findEmploymentHistoryById()
        .then((response) => {
          commit('setEmploymentHistory', response.data)
          commit('setEmploymentHistoryStatus', 'success')
        })
        .catch(() => {
          commit('setEmploymentHistoryStatus', 'error')
        })
    },
    async fetchApi ({ commit }) {
      // console.log()
      commit('setRecognitionDataStatus', 'pending')
      healthService.getMedicalExamination()
        .then((response) => {
          const a = JSON.parse(JSON.stringify(response.data))
          const b = JSON.parse(JSON.stringify(response.data))

          a.forEach(element => {
            element.type = Vue.CONSTANTS.CARD_TYPE.PHYSICAL_EXAMINATION
          })

          b.forEach(element => {
            element.type = Vue.CONSTANTS.CARD_TYPE.FITNESS_SUMMARY
          })
          console.log(a)
          console.log(b)
          commit('setRecognitionData', a)
          commit('setRecognitionDataStatus', 'success')

          commit('setProficiencyReports', b)
          commit('setProficiencyReportsStatus', 'success')
        })
        .catch(() => {
          commit('setRecognitionDataStatus', 'error')
          commit('setProficiencyReportsStatus', 'error')
        })
        .finally(() => {
        })
      // training received
      commit('setTrainingReceivedStatus', 'pending')
      preventionService.getTrainingReceived()
        .then((response) => {
          commit('setTrainingReceived', response.data)
          commit('setTrainingReceivedStatus', 'success')
        })
        .catch(() => {
          commit('setTrainingReceivedStatus', 'error')
        })
        .finally(() => {
        })
      // analytics
      commit('setAnalyticsStatus', 'pending')
      healthService.getHealthAnalytics()
        .then((response) => {
          const c = JSON.parse(JSON.stringify(response.data))
          c.forEach(element => {
            element.type = Vue.CONSTANTS.CARD_TYPE.ANALYTICS
          })
          commit('setAnalytics', c)
          commit('setAnalyticsStatus', 'success')
        })
        .catch(() => {
          commit('setAnalyticsStatus', 'error')
        })
        .finally(() => {
        })
      // other tests
      commit('setOtherTestsStatus', 'pending')
      healthService.getOtherActivities()
        .then((response) => {
          commit('setOtherTests', response.data)
          commit('setOtherTestsStatus', 'success')
        })
        .catch(() => {
          commit('setOtherTestsStatus', 'error')
        })
        .finally(() => {
        })
      // other documents getOtherDocuments
      commit('setOtherDocumentsStatus', 'pending')
      healthService.getOtherDocuments()
        .then((response) => {
          commit('setOtherDocuments', response.data)
          commit('setOtherDocumentsStatus', 'success')
        })
        .catch(() => {
          commit('setOtherDocumentsStatus', 'error')
        })
        .finally(() => {
        })
      // employment history getOtherDocuments
      commit('setEmploymentHistoryStatus', 'pending')
      activitiesService.findEmploymentHistoryById()
        .then((response) => {
          commit('setEmploymentHistory', response.data)
          commit('setEmploymentHistoryStatus', 'success')
        })
        .catch(() => {
          commit('setEmploymentHistoryStatus', 'error')
        })
        .finally(() => {
        })
      // provinces
      commit('setProvincesStatus', 'pending')
      userService.getProvinces()
        .then((response) => {
          commit('setProvinces', response.data)
          commit('setProvincesStatus', 'success')
        })
        .catch(() => {
          commit('setProvincesStatus', 'error')
        })
        .finally(() => {
        })
    }
  }
}
