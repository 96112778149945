<template>
    <div class="checkbox__container" :class="{'checkbox__container--selected' : data.selected}" @click="setState()">
        <span class="checkbox__icon icon" :class="data.selected ? 'icon-checkbox-label-active' : 'icon-checkbox-label'"></span>
        <span class="checkbox__label" v-html="data.label"></span>
    </div>
</template>
<script>
export default {
  name: 'Checkbox',
  props: ['data'],
  data () {
    return {
      selected: this.data.selected || false
    }
  },
  methods: {
    setState: function () {
      this.selected = !this.selected
      this.$emit('onChange', this.selected)
    }
  }
}
</script>
