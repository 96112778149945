<template>
    <div class="welcome-view__form__modal welcome-view__form__modal--legal-text">
        <span class="welcome-view__form__modal__close icon icon-close" @click="closeModal()"></span>
        <div class="welcome-view__form__modal__content">
            <div class="">
            <p class="welcome-view__form__subtitle">{{ getLegalContent.title }}</p>
            <p v-html="texts"></p>
            <div class="welcome-view__form__modal__check-legal-container" v-if="mode">
              <Checkbox :data="checkboxData" @onChange="checkTerms($event)" />
            </div>
              <Button :label="$t('global.continue')"
                      :classes="'button--cta welcome-view__form__modal__button'"
                      :isLoading="isLoading"
                      :disabledButton="!checkboxData.selected"
                      @click.native="acceptTerms(type)"
                      v-if="mode" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Button from '../ui/Button.vue'
import Checkbox from '../ui/Checkbox.vue'
export default {
  components: { Button, Checkbox },
  name: 'LegalModal',
  props: ['texts', 'mode', 'type', 'isLoading'],
  data () {
    return {
      checkboxData: { label: this.$t('login.acceptServiceTerms'), selected: true }
    }
  },
  computed: {
    ...mapGetters(['getLegalContent'])
  },
  mounted () {},
  methods: {
    closeModal () {
      // console.log()
      if (this.type === 'COOKIES') this.$store.commit('setCookiesPreferencesOpened', false)
      this.$emit('onClose')
    },
    checkTerms (state) {
      // console.log()
      this.checkboxData.selected = state
    },
    acceptTerms (event) {
      // console.log()
      this.$emit('onAccept', event)
    }
  }
}
</script>

<style>

  h2 {
    font-family: Helvetica,Arial,sans-serif !important;
    margin-bottom: 10px;
  }

</style>
