<template>
  <div class="welcome-view">
    <div class="welcome-view__container">
      <div class="welcome-view__image-container">
        <WelcomeImage />
      </div>
      <div class="welcome-view__form-container" v-if="isModalVisible">
        <!-- MODAL LEGAL TEXTS -->
        <LegalModal :texts="text" :type="legalContent" :mode="false" @onClose="showPrevingTerms(false)" v-if="isModalVisible && isShowPrevingTermsVisible"/>
      </div>
      <div class="welcome-view__form-container" v-if="!isModalVisible">
       <WelcomeLanguageSelector />
        <div class="welcome-view__form-content">
          <img
            :src="getLogo()"
            class="welcome-view__form__logo margin-bottom-40"
          />
          <div class="width-100-percent">
            <p
              class="
                welcome-image__title
                welcome-view__form__title
                mobile-visible
              "
              v-html="$t('welcome.title')"
            ></p>
            <div v-if="resetError" class="form-message margin-bottom-30">
              <span class="form-message__text">{{ this.defaultError }}</span>
            </div>
            <div class="welcome-view__form__message" v-if="sendedEmail">
                <p class="welcome-view__form__subtitle">{{ $t("resetPassword.checkYourInbox") }}</p>
                <span class="welcome-view__form__message__icon icon icon-email"></span>
                <p class="welcome-view__form__message__email">{{username}}</p>
                <p class="welcome-view__form__message__text">{{ $t("resetPassword.sendedEmailMessage")}}</p>
                <p class="welcome-view__form__message__small-text">{{ $t("resetPassword.sendedEmailProblemMessage")}}</p>
                <div class="login-back-button margin-top-40" @click="setRememberUsername(true)">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label" @click="navigateTo('login')">{{ $t('resetPassword.startSession') }}</span>
              </div>
            </div>
            <div class="welcome-view__form__message" v-if="!rememberUsername && !sendedEmail">
                <p class="welcome-view__form__subtitle">{{ $t("resetPassword.rememberUsername") }}</p>
                <span class="welcome-view__form__message__icon icon icon-email-not-found"></span>
                <p class="welcome-view__form__message__text" v-html="$t('resetPassword.rememberIdentificator')"></p>
                <div class="login-back-button margin-top-40" @click="setRememberUsername(true)">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label">{{ $t('global.back') }}</span>
              </div>
            </div>
            <div class="welcome-view__form" v-if="rememberUsername && !sendedEmail">
              <p class="welcome-view__form__subtitle">{{ $t("resetPassword.restorePassword") }}</p>
              <div class="form-group">
                <div class="form-group__label">{{ $t("login.username") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('login.usernamePlaceholder')"
                    class="input"
                    v-model="username"
                  />
                  <div class="form-group__message" v-if="nifErrorMessage">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ this.nifErrorMessage }}</span>
                  </div>
                  <div class="form-group__message" v-if="defaultError">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ this.defaultError }}</span>
                  </div>
                </div>
              </div>
              <div class="flex justify-end margin-top-5" @click="setRememberUsername(false)">
                <a href="javascript:void(0);" class="link">{{ $t("resetPassword.dontRememberUsername") }}</a>
              </div>

              <!-- demo -->
              <div class="flex direction-column margin-top-20">
                <vue-recaptcha
                  class="captcha__container"
                  ref="recaptcha"
                  :sitekey="recaptchaKey"
                  :loadRecaptchaScript="true"
                  @verify="captchaSuccess">
                </vue-recaptcha>
              </div>

              <!--
              <div class="flex direction-column margin-top-20">
                <vue-recaptcha
                  class="captcha__container"
                  ref="recaptcha"
                  sitekey="6LepnZ4eAAAAAH7PPUUmCQQ54QfIa6JYzKyXT8w6"
                  :loadRecaptchaScript="true"
                  @verify="captchaSuccess">
                </vue-recaptcha>
              </div>
              -->

              <Button :label="$t('resetPassword.restorePassword')"
              :classes="'button--cta button--full-width margin-top-40'"
              :isLoading="isLoading"
              @click.native="sendEmailToResetPassword()" />
              <div class="login-back-button margin-top-40" @click="navigateTo('login')">
                <span class="login-back-button__icon icon icon-arrow-left"></span>
                <span class="login-back-button__label">{{ $t('resetPassword.startSession') }}</span>
              </div>
            </div>
          </div>
          <LegalLinks @onClickLink="showPrevingTerms(true, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import logoContigo from '@/assets/images/logo-preving-contigo.svg'
import WelcomeImage from '@/components/welcome/WelcomeImage'
import userService from '@/services/userService.js'
import Button from '@/components/ui/Button.vue'
import WelcomeLanguageSelector from '@/components/welcome/WelcomeLanguageSelector'
import LegalLinks from '@/components/welcome/LegalLinks.vue'
import LegalModal from '@/components/welcome/LegalModal.vue'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'ResetPassword',
  components: { WelcomeImage, WelcomeLanguageSelector, Button, LegalLinks, LegalModal, VueRecaptcha },
  data () {
    return {
      resetError: false,
      text: null,
      legalContent: null,
      mode: null,
      serviceTerms: null,
      serviceTermsi18n: null,
      privacyTerms: null,
      privacyTermsi18n: null,
      cookiesTerms: null,
      cookiesTermsi18n: null,
      username: '',
      defaultError: null,
      nifErrorMessage: null,
      rememberUsername: true,
      sendedEmail: false,
      isLoading: false,
      captchaVerified: false,
      isModalVisible: false,
      legalModal: {},
      isShowPrevingTermsVisible: false,
      rules: {
        required: value => !!value,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value)
        }
      },
      recaptchaKey: window.VUE_APP_RECAPTCHA
    }
  },
  mounted () {
    if (this.getServiceTerms) {
      this.serviceTermsi18n = this.getServiceTerms
      this.setServiceTerms()
    }

    if (this.getPrivacyTerms) {
      this.privacyTermsi18n = this.getPrivacyTerms
      this.setPrivacyTerms()
    }

    if (this.getCookiesTerms) {
      this.cookiesTermsi18n = this.getCookiesTerms
      this.setCookiesTerms()
    }
  },
  methods: {
    setServiceTerms: function () {
      this.serviceTerms = decodeURIComponent(atob(this.serviceTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setPrivacyTerms: function () {
      this.privacyTerms = decodeURIComponent(atob(this.privacyTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setCookiesTerms: function () {
      this.cookiesTerms = decodeURIComponent(atob(this.cookiesTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    showPrevingTerms (visibility, legalContent, mode) {
      if (legalContent === 'COOKIES') {
        this.$store.dispatch('showCookiesBar')
        return
      }
      this.isModalVisible = visibility
      this.legalContent = legalContent
      this.isShowPrevingTermsVisible = visibility
      if (legalContent === 'TERMS') {
        this.text = this.serviceTerms
      } else if (legalContent === 'PRIVACY') {
        this.text = this.privacyTerms
      }
      this.mode = mode
      window.scrollTo(0, 0)
      // this.$store.commit('setLegalContent', legalContent)
    },
    navigateTo (path) {
      this.$router.push({ path: path })
    },
    setRememberUsername: function (rememberUsername) {
      this.rememberUsername = rememberUsername
    },
    getLogo: function () {
      return logoContigo
    },
    validateForm: function () {
      if (true) {}
    },
    sendEmailToResetPassword () {
      if (this.captchaVerified !== true) {
        return
      }
      if (this.formHasErrors() === true) {
        return
      }
      this.isLoading = true
      this.defaultError = null
      userService.sendEmailToResetPassword(this.username)
        .then((response) => {
          this.sendedEmail = true
        })
        .catch((error) => {
          this.isLoading = false
          if (error.response === undefined) {
            this.defaultError = this.$t('errorMessage.generic')
            return
          }
          this.defaultError = this.$t('errorMessage.' + error.response.data.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    formHasErrors: function () {
      this.nifErrorMessage = null
      let hasErrors = false

      if (this.rules.required(this.username) !== true) {
        hasErrors = true
        this.nifErrorMessage = this.$i18n.t('form.validation.required')
      }

      if (this.username && this.username.includes('@')) {
        if (this.rules.email(this.username) !== true) {
          hasErrors = true
          this.nifErrorMessage = this.$i18n.t('form.validation.email')
        }
      }

      return hasErrors
    },
    captchaSuccess: function () {
      this.captchaVerified = true
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getLanguages', 'getPrivacyTerms', 'getServiceTerms', 'getCookiesTerms', 'getLocale', 'getCookiesPreferencesOpened'])
  },
  watch: {
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        this.setPrivacyTerms()
        this.setServiceTerms()
      },
      deep: true
    },
    getPrivacyTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.privacyTermsi18n = this.getPrivacyTerms
          this.setPrivacyTerms()
        }
      },
      deep: true
    },
    getServiceTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.serviceTermsi18n = this.getServiceTerms
          this.setServiceTerms()
        }
      },
      deep: true
    },
    getCookiesTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.cookiesTermsi18n = this.getCookiesTerms
          this.setCookiesTerms()
        }
      },
      deep: true
    },
    getCookiesPreferencesOpened: {
      handler (newVal, oldVal) {
        if (newVal === true) {
          // this.showPrevingTerms(true, 'COOKIES', false)
          this.isModalVisible = newVal
          this.legalContent = 'COOKIES'
          this.isShowPrevingTermsVisible = newVal
          this.text = this.cookiesTerms
          this.mode = false
          window.scrollTo(0, 0)
        }
      },
      deep: true
    }
  }
}
</script>
