import * as Crypto from './crypto.helper.js'
import * as userStore from '../store/userStore.js'
// import router from '@/router/index.js'

import moment from 'moment'

// APPOINTMENT MODULES
export function checkModules () {
  getClientModules()
  // appointments
  if (appointmentsDisabled()) {
    userStore.default.mutations.setModuleAppointment(userStore.default.state, 0)
  } else {
    userStore.default.mutations.setModuleAppointment(userStore.default.state, 1)
  }
}

export function addNewAppointment (appointment) {
  let appointments = []
  appointments = JSON.parse(Crypto.decrypt(userStore.default.getters.getAppointments(userStore.default.state)))
  appointments.push(appointment)

  const encodeAppointments = Crypto.encrypt(JSON.stringify(appointments))
  userStore.default.mutations.setAppointments(userStore.default.state, encodeAppointments)
  sessionStorage.setItem('_appointments', encodeAppointments)
}

export function getAppointments () {
  let appointments = []
  try {
    appointments = JSON.parse(Crypto.decrypt(userStore.default.getters.getAppointments(userStore.default.state)))
    console.log(appointments)
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_appointments')
    // router.push('/login')
  }

  return appointments
}

export function appointmentsDisabled () {
  let appointments = []
  try {
    appointments = JSON.parse(Crypto.decrypt(userStore.default.getters.getAppointments(userStore.default.state)))
    console.log(appointments)
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_appointments')
    // router.push('/login')
  }
  let lastAppointment = null
  console.log(appointments)
  if (appointments.length === 0) return
  if (Object.keys(appointments.appointments).length > 0) {
    const x = appointments.appointments.filter(x => x.anulada === 0)
    if (x.length > 0) {
      x.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      const now = moment().format('YYYYMMDD')
      if (now < moment(x[0].date).format('YYYYMMDD')) lastAppointment = x[0]
    }
  }

  return {
    appointments: appointments.appointments,
    checkExpiredDate: checkExpiredDate(appointments.appointments) > 0,
    hasVs: hasVs(),
    lastAppointment: lastAppointment
  }
  // return disabled -> false -> the user can request appointments
  /*
  if (!hasVs()) return true
  return checkExpiredDate(appointments) > 0
  */
}

export function checkExpiredDate (array) {
  // const now = moment().format('YYYYMMDD')
  let count = 0
  console.log('count array', array)
  array.forEach(element => {
    // if (now < moment(element.date).format('YYYYMMDD')) count += 1
    if (element.anulada === 0) {
      if (new Date(moment()) < new Date(element.date)) count += 1
    }
  })
  console.log('count', count)
  // console.log()
  return count
}

// CLIENT MODULES
export function getClientModules () {
  let clientModules = []
  try {
    clientModules = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).clientModules
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }

  return clientModules
}

export function hasPt () {
  let clientModules = []
  try {
    clientModules = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).clientModules
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }
  let returnPt = false
  clientModules.forEach(client => {
    if (client.pt) returnPt = true
  })

  return returnPt
  // console.log(returnPt)
  // return false
}

export function hasVs () {
  let clientModules = []
  try {
    clientModules = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).clientModules
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }
  let returnVs = false
  clientModules.forEach(client => {
    if (client.vs) returnVs = true
  })

  return returnVs
}

// //////////////////////////////////////////// TLM AND TPS /////////////////////////////////////////////

export function hasTps () {
  let externalPlatforms = null
  try {
    externalPlatforms = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).externalPlatforms
    console.log(JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))))
    console.log(externalPlatforms)
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }
  if (externalPlatforms) {
    return externalPlatforms.tps
  } else {
    return false
  }
}

export function tpsCode () {
  let externalPlatforms = null
  try {
    externalPlatforms = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).externalPlatforms
    console.log(externalPlatforms)
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }
  if (externalPlatforms) {
    return externalPlatforms.tpsCode
  } else {
    return null
  }
}

export function hasTelemedicina () {
  let externalPlatforms = null
  try {
    externalPlatforms = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).externalPlatforms
    console.log(externalPlatforms)
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }
  if (externalPlatforms) {
    return externalPlatforms.tlm
  } else {
    return false
  }
}

export function tlmCode () {
  let externalPlatforms = null
  try {
    externalPlatforms = JSON.parse(Crypto.decrypt(userStore.default.getters.getClientModules(userStore.default.state))).externalPlatforms
    console.log(externalPlatforms)
  } catch (error) {
    // console.log()
    sessionStorage.removeItem('_modules')
    // router.push('/login')
  }
  if (externalPlatforms) {
    return externalPlatforms.tlmCode
  } else {
    return null
  }
}
