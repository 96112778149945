<template>
  <!--
    TODO El grupo de etiqueta + radiobuttons debe de estar contenido en un <fieldset>
    y la etiqueta en sí debe de ser un <legend>
     -->
  <div :class="[numberLabel ? 'form-check-group boxing-style' : 'form-check-group', labelOnTop ? 'grid-options' : '']">
    <!-- :class="[numberLabel ? 'form-radio-group boxing-style' : 'form-radio-group']" -->
    <div class="form-check-group__header">
      <div
        v-if="numberLabel"
        class="card__icon-container"
      >
        <span class="card__icon">{{ numberLabel }}</span>
        <!-- <span class="card__icon icon icon-info-toast" /> -->
      </div>
      <p class="form-check">
        {{ $tc( textLabel ) }}
        <span
          v-if="requiredInput"
          :title="requiredTitle"
          class="required-dot"
        >●</span>
      </p>
    </div>
    <div class="checks-container">
      <div
        v-for="(radio, index) in checkboxOptions"
        :key="index+textLabel"
      >
        <input
          :id="textLabel+radio.label"
          :key="index+textLabel"
          v-model="inputVal"
          type="checkbox"
          :name="groupName"
          :value="radio.label"
          @input="inputHandler"
        ><!-- @input="inputHandler" --> <!-- false-value="no" --> <!-- :value="radio.forId" -->
        <label
          :key="index+textLabel+1"
          :for="textLabel+radio.label"
          class="form-check__label"
        >{{ $tc( radio.label ) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CheckboxesLabel',
  props: {
    numberLabel: {
      type: Number,
      required: false,
      default: null
    },
    textLabel: {
      type: String,
      required: false,
      default: null
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    value: {
      // eslint-disable-next-line
      type: String | Number,
      required: false,
      default: null
    },
    groupName: {
      type: String,
      required: false,
      default: ''
    },
    formElement: {
      type: String,
      required: false,
      default: ''
    },
    checkboxOptions: {
      type: Array,
      required: false,
      default: () => [
        {
          label: 'form.no',
          forId: 'no',
          value: false
        },
        {
          label: 'form.yes',
          forId: 'yes',
          value: true
        }
      ]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    requiredInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkboxesData: []
    }
  },
  computed: {
    requiredTitle () {
      return this.$t('form.validation.required')
    },
    inputVal: {
      get () {
        console.log(this.checkboxesData)
        console.log(this.$attrs)
        return this.checkboxesData
      },
      set (value) {
        // this.$emit('input', this.inputVal)
        console.log(value)
        this.checkboxesData = value
        console.log(this.checkboxesData)
      }
    }
  },
  methods: {
    inputHandler (event) {
      /*
      console.log('entra aqui', event.target.value)
      console.log('entra aqui', event.target.checked)

      if (event.target.checked) {
        const find = this.checkboxesData.find(x => x === event.target.value)
        if (find) this.checkboxesData.splice(this.checkboxesData.indexOf(find), 1)
      }
      */
      console.log(this.checkboxesData)
      // this.$emit('input', event.target.value)
    }
  },
  watch: {
    checkboxesData (newQuestion, oldQuestion) {
      console.log(newQuestion)
      this.$emit('input', this.checkboxesData)
    },
    inputVal (n, o) {
      console.log(n, o)
    },
    value (n, o) {
      console.log(n, o)
      this.inputVal = n
    }
  }
  // methods: {
  //   inputHandler (event) {
  //     console.log('event', event.target.value)
  //   }
  // }
}
</script>

<style lang="scss">

 .form-check-group {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  &.grid-options {
    display: grid;
    & .checks-container {
      grid-template-columns: repeat(auto-fit, minmax(300px, 50px));
      grid-auto-flow: unset !important;
      gap: 15px !important;
      grid-column-end: 4;
      grid-column-start: 1;
      margin-left: 50px;
    }
  }
  & .form-check-group__header {
    // width: 200px;
    display: flex;
    align-items: center;
    line-height: 1.5;
    color: var(--black-color);
    min-height: 40px;
    display: flex;
    align-items: center;
    & .form-check {
      font-style: normal;
      margin: 0;
    }
  }
  & .checks-container {
    display: grid;
    min-width: max-content;
    // grid-auto-flow: column;
    align-items: baseline;
    column-gap: 50px;
    row-gap: 20px;
    grid-template-columns: auto auto;
    & .form-check__label {
      // margin-left: 8px;
      margin-right: 15px;
      font-style: normal;
      font-weight: 400;
      padding: 5px 15px;
      // line-height: 160%;
      cursor: pointer
    }
    input[type="radio"] {
      transform: scale(1.5);
      cursor: pointer;
      filter: saturate(30%);
    }
  }
  &.boxing-style {
    justify-content: space-between;
    gap: 15px;
    margin-top: 26px;
    padding: 24px 24px 24px 0px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    & .form-check-group__header {
      & .card__icon-container {
        width: 26px;
        height: 26px;
        min-height: 26px;
        min-width: 26px;
        background-color: #00425c;
        & .card__icon {
          color: #fff;
          font-size: 14px;
          font-weight: 800;
          margin-top: 3px;
        }
      }
      & .form-check {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
    & .checks-container {
    display: grid;
    min-width: max-content;
    grid-auto-flow: column;
    align-items: baseline;
    gap: 50px;
    & .form-check__label {
      // margin-left: 8px;
      margin-right: 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      // line-height: 160%;
      color: #666666;
      padding: 5px 15px;
      cursor: pointer
    }
    input[type="checkbox"] {
      transform: scale(1.5);
      cursor: pointer;
      filter: saturate(30%);
    }
  }
  }
}

.required-dot {
  color: var(--primary-dark-color);
  line-height: 17px;
  margin: 0 5px;
  cursor: help;
}

@media (max-width: 800px) {
  .form-check-group {
        align-items: flex-start !important;
  }
  .form-check-group__header {
    align-items: flex-start !important;
  }
  .checks-container {
    grid-template-columns: auto !important;
    row-gap: 10px !important;
  }
  .form-check__label {
    display: inline-block !important;
  }
  .boxing-style {
    flex-direction: column;
    & .form-check-group__header {
      width: 100%;
      & .card__icon-container {
        position: relative;
      }
      p {
        margin-top: 5px;
      }
    }
    & .checks-container {
          margin-left: 46px;
    }
  }

}
</style>
