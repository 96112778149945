<template>
  <div class="section__container section__container--three-columns">
      <div class="">
        <AsideMenu :data="getMenu[5].items" @onChangeMenu="loadSection($event)" v-if="getMenu.length > 0"/>
        <Banners class="margin-top-20" v-if="getCurrentDevice === DEVICES.TABLET" />
      </div>
        <div class="section__content" v-if="getParamSection">
            <component :is="getParamSection.component" />
        </div>
        <div class="">
          <Banners v-if="getCurrentDevice !== DEVICES.TABLET" />
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import userService from '@/services/userService.js'
import MyWorkPlace from '@/components/general-info/MyWorkPlace.vue'
import EmploymentHistory from '@/components/general-info/EmploymentHistory.vue'
import AsideMenu from '@/components/commons/AsideMenu.vue'
import ProfileSection from '@/components/my-profile/ProfileSection.vue'
import ProfileResetPassword from '@/components/my-profile/ProfileResetPassword.vue'
import Banners from '@/components/commons/Banners.vue'
export default {
  name: 'MyProfile',
  components: { AsideMenu, Banners, EmploymentHistory, MyWorkPlace, ProfileSection, ProfileResetPassword },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getCurrentDevice', 'getParamSection'])
  },
  created () {
    window.addEventListener('resize', this.onResize)
    // console.log()
  },
  mounted () {
    this.menu = [...this.getMenu]
    if (!this.getParamSection) {
      this.$store.commit('setParamSection', this.getMenu[5].items[0])
      // console.log()
      this.getMenu[5].items[0].selected = true
      this.getMenu[5].opened = true
      this.$store.commit('setMenu', this.menu)
    }
    this.loadSection(this.getParamSection)
  },
  methods: {
    loadSection: function (section) {
      // console.log()
      this.$store.commit('setParamSection', section)
      this.menu = [...this.getMenu]
      if (!section.selected) {
        this.menu[5].items.forEach(element => {
          element.selected = false
        })
        section.selected = true
        this.menu[5].opened = true
      }
      this.$store.commit('setMenu', this.menu)
    }
  }
}
</script>
