<template>
  <div class="chart-box__header">
    <span
      v-if="showHelpTooltip"
      v-tooltip="{ content: helpTooltip, delay: 1, offset: 5, visible: true, class: 'tooltip-chart' }"
      class="icon icon-help"
    />
    <h3 :style="[{'visibility': showTitle ? 'unset' : 'hidden'}]">{{ title }}</h3>
    <h4 v-if="subtitle">{{ subtitle }}</h4>
    <div class="legend">
      <div v-if="showLegend">
        <span v-if="borderColor" class="icon icon-remove" :style="[{'color': borderColor}]" />
        <span class="legend--title">{{ legend }}</span>
        <span v-if="showSecondLegend" class="icon icon-remove" :style="[{'color': borderColor2}]" />
        <span v-if="showSecondLegend" class="legend--title">{{ legend2 }}</span>
      </div>
      <div v-else-if="entity !== 'bloodPress' && entity !== 'vision' && entity !== 'vision-left' && entity !== 'vision-right'">
        <span class="legend--title">{{ $t('digitalHealth.lastMeasure') }}</span>
      </div>
      <!-- <div v-if="heavyData2 != 'NaN' ">
        <span class="icon icon-remove" :style="[{'color': borderColor[1]}]" />
        <span class="legend--title">{{ legend2 }}</span>
      </div>
      <div v-if="heavyData3 != 'NaN' ">
        <span class="icon icon-remove" :style="[{'color': borderColor[2]}]" />
        <span class="legend--title">{{ legend3 }}</span>
      </div> -->
    </div>
    <div v-if="showPositiveNegative" class="heavy-data">
      <span :class="['heavy-data--number', heavyData > 0 ? '' : 'negative']">{{ heavyData === 1 ? _capitalize($t('global.negative')) : _capitalize($t('global.positive')) }}</span>
    </div>
    <div v-if="showNormalLow" class="heavy-data">
      <span :class="['heavy-data--number', heavyData > 0 ? '' : 'altered']">{{ heavyData === 1 ? _capitalize($t('global.regular')) : _capitalize($t('global.altered')) }}</span>
    </div>
    <!-- HEADER ONLY FOR BLOODPRESS -->
    <div v-else-if="entity === 'bloodPress'" class="heavy-data">
      <span :class="['heavy-data--number', heavyData < 0 ? 'negative' : '']">{{ heavyDataNum }}</span>
      <span class="heavy-data--measurement--little">{{ measurement }}</span>
      <span :class="['heavy-data--number', heavyData2 < 0 ? 'negative' : '']">{{ heavyData2Num }}</span>
      <span class="heavy-data--measurement--little">{{ measurement }}</span>
    </div>
    <!-- <div v-else-if="entity === 'vision'" class="heavy-data" :style="{'grid-template-columns': styleGrid}">
      <span :class="['heavy-data--number', heavyData < 0 ? 'negative' : '']">{{ heavyData }}</span>
      <span class="heavy-data--measurement--little">{{ measurement }}</span>
      <span :class="['heavy-data--number', heavyData2 < 0 ? 'negative' : '']">{{ heavyData2 }}</span>
      <span class="heavy-data--measurement--little">{{ measurement }}</span>
      <span :class="['heavy-data--number', heavyData3 < 0 ? 'negative' : '']">{{ heavyData3 }}</span>
      <span class="heavy-data--measurement--little">{{ measurement }}</span>
    </div> -->
    <div v-if="showHeavyData" class="heavy-data">
      <div class="heavy-data-content">
        <span :class="['heavy-data--number', heavyData < 0 ? 'negative' : '']">{{ heavyDataNum }}</span>
      <span class="heavy-data--measurement">{{ measurement }}</span>
      </div>
      <div class="legend-other" v-if="entity === 'hearing-left' || entity === 'hearing-right'">
        <span v-for="(item, index) in subLabels" :key="index"><span v-if="borderColor" class="icon icon-remove" :style="[{'color': getBorderColor(index)}]" />{{ item }}</span>
        <!-- <span><span v-if="borderColor" class="icon icon-remove" :style="[{'color': borderColor}]" /> hola</span>
        <span v-if="borderColor" class="icon icon-remove" :style="[{'color': borderColor}]" /> -->
      </div>
      <div class="legend-other" v-if="entity === 'vision-left' || entity === 'vision-right' || entity === 'vision'">
        <span v-for="(item, index) in subLabels" :key="index"><span v-if="borderColor" class="icon icon-remove" :style="[{'color': getBorderColor(index)}]" />{{ $t('digitalHealth.charts.' + entity + '.' + item) }}</span>
        <!-- <span><span v-if="borderColor" class="icon icon-remove" :style="[{'color': borderColor}]" /> hola</span>
        <span v-if="borderColor" class="icon icon-remove" :style="[{'color': borderColor}]" /> -->
      </div>
    </div>
    <div class="date">
      <span>{{ getChartLastdate }}</span>
    </div>
  </div>
</template>
<script>
import { capitalize } from '@/mixins/formatText'
import moment from 'moment'

export default {
  name: 'ChartBoxHeader',
  components: {},
  props: {
    entity: {
      type: String,
      require: false,
      default: ''
    },
    subtitle: {
      type: String,
      require: false,
      default: ''
    },
    showTitle: {
      type: Boolean,
      require: true,
      default: true
    },
    heavyData: {
      type: Number,
      require: true,
      default: null
    },
    heavyData2: {
      type: Number,
      require: false,
      default: null
    },
    heavyData3: {
      type: Number,
      require: false,
      default: null
    },
    chartLastdate: {
      type: String,
      require: true,
      default: ''
    },
    borderColor: {
      type: String,
      require: true,
      default: null
    },
    borderColor2: {
      type: String,
      require: true,
      default: '#fff000'
    },
    borderColorPrimary: {
      type: String,
      require: false,
      default: null
    },
    subLabels: {
      type: Array,
      require: true,
      default: () => []
    }
  },
  computed: {
    getChartLastdate () {
      return moment(Date(moment(this.chartLastdate))).lang(this.$i18n.locale).format('LL')
    },
    title () {
      const target = `digitalHealth.charts.${this.entity}.title`
      return this.$t(target)
    },
    legend () {
      const target = `digitalHealth.charts.${this.entity}.legend`
      return this.$t(target)
    },
    legend2 () {
      const target = `digitalHealth.charts.${this.entity}.legend2`
      return this.$t(target)
    },
    legend3 () {
      const target = `digitalHealth.charts.${this.entity}.legend3`
      return this.$t(target)
    },
    measurement () {
      const target = `digitalHealth.charts.${this.entity}.measurement`
      return this.$t(target)
    },
    showLegend () {
      // return this.entity !== 'urineGlucose' && this.entity !== 'bloodPress' && this.entity !== 'spirometry'
      return this.entity !== 'urineGlucose' && this.entity !== 'vision' && this.entity !== 'vision-left' && this.entity !== 'vision-right'
    },
    showSecondLegend () {
      // return this.entity !== 'urineGlucose' && this.entity !== 'bloodPress'
      return this.entity === 'bloodPress'
    },
    showPositiveNegative () {
      return this.entity === 'urineGlucose'
    },
    showNormalLow () {
      return this.entity === 'spirometry'
    },
    showHeavyData () {
      return this.entity !== 'urineGlucose' && this.entity !== 'bloodPress' && this.entity !== 'spirometry'
    },
    heavyDataNum () {
      const number = this.heavyData
      return new Intl.NumberFormat('es-ES').format(number)
    },
    heavyData2Num () {
      const number = this.heavyData2
      return new Intl.NumberFormat('es-ES').format(number)
    },
    heavyData3Num () {
      const number = this.heavyData3
      return new Intl.NumberFormat('es-ES').format(number)
    },
    showHelpTooltip () {
      const target = `digitalHealth.charts.${this.entity}.help`
      return this.$t(target) !== '' && this.$t(target) !== null
    },
    helpTooltip () {
      const target = `digitalHealth.charts.${this.entity}.help`
      return this.$tc(target)
    }
  },
  data () {
    return {
      color: 129
    }
  },
  mounted () {
    console.log(this.heavyData)
  },
  methods: {
    _capitalize (word) {
      return capitalize(word)
    },
    getBorderColor (index) {
      if (index === 0) {
        if (this.borderColorPrimary) {
          return this.borderColorPrimary
        } else {
          return this.borderColor
        }
      }
      const a = this.color - (index * 30)
      console.log(a)
      return `${this.borderColor}${a}`
      // let color
      // if (index !== 0) {
      //   color = `${this.borderColor}${this.color}`
      //   this.color -= 30
      // } else {
      //   color = this.borderColor
      // }
      // console.log(color)
      // return color
    }
  }
}
</script>

<style lang="scss">

@import 'src/assets/scss/settings/variables';
@import 'src/assets/scss/settings/colors';

  .legend-other span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .heavy-data-content {
  }

  .tooltip-chart {
    color: var(--black-85-color);
    background-color: white;
    line-height: $line-height-xxl;
    box-shadow: $depth-03;
    padding: 15px;
  }
</style>
